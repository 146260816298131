import { p as proxyCustomElement, H, h, c as Host } from './p-25bf8c52.js';
import { b as generateElementId } from './p-604842db.js';
import { a as accessibilityAttributes } from './p-756e7dc5.js';
import { s as stopEvent } from './p-a00c815e.js';
import { a as ComponentUtils } from './p-cf58d171.js';
import { d as defineCustomElement$2 } from './p-47252d80.js';
const cookieAcceptanceOptionCss = ".sc-owc-cookie-acceptance-option-h{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;color:var(--one-color-foreground-base);padding:var(--one-spacing-padding-4-xs) var(--one-spacing-padding-2-xs);gap:var(--one-spacing-gap-4-xs)}.sc-owc-cookie-acceptance-option-h .owc-cookie-acceptance-option__option-text-container.sc-owc-cookie-acceptance-option{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;font-family:var(--one-typography-font-family);font-size:var(--one-typography-desktop-body-1-font-size);line-height:var(--one-typography-desktop-body-1-line-height);-webkit-font-kerning:var(--one-typography-desktop-body-1-font-kerning);font-kerning:var(--one-typography-desktop-body-1-font-kerning);font-weight:var(--one-text-font-weight-regular);gap:var(--one-spacing-gap-4-xs)}.sc-owc-cookie-acceptance-option-h .owc-cookie-acceptance-option__option-text-container__name.sc-owc-cookie-acceptance-option{font-family:var(--one-typography-font-family);font-size:var(--one-typography-desktop-subtitle-1-font-size);line-height:var(--one-typography-desktop-subtitle-1-line-height);-webkit-font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-weight:var(--one-text-font-weight-medium)}";
const OwcCookieAcceptanceOptionStyle0 = cookieAcceptanceOptionCss;
const CookieAcceptanceOption = /*@__PURE__*/proxyCustomElement(class CookieAcceptanceOption extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
    this.descriptionID = generateElementId('description');
    this.name = undefined;
    this.disabled = undefined;
    this.accepted = false;
  }
  render() {
    const hostClasses = this.component.getHostBemClasses();
    const cookieOptionTextContainer = this.component.getChildBemClasses('option-text-container', hostClasses);
    const nameClasses = this.component.getChildBemClasses('name', cookieOptionTextContainer);
    return h(Host, {
      key: 'bb3dd92cdfe5d84ebe7d6a14f555495d22a36f48',
      class: hostClasses
    }, h("owc-switch", {
      key: '042bf07461bc5647e0ec149737eac242691ae1e8',
      disabled: this.disabled,
      checked: this.accepted,
      nativeInputProps: Object.assign({}, accessibilityAttributes({
        label: this.name,
        describedby: this.descriptionID
      })),
      onValueChange: e => stopEvent(e, () => this.accepted = e.detail)
    }), h("div", {
      key: 'c6c6d10d80704fbdcc16faad4ffbc6b0408354a5',
      class: cookieOptionTextContainer
    }, this.name ? h("span", {
      class: nameClasses
    }, this.name) : null, h("div", {
      key: '7a46b97870fb6016641edbf3c81257873c49fc95',
      id: this.descriptionID
    }, h("slot", {
      key: '2fbe63a5955768c8334ce7fbf2a03d7af79183a8'
    }))));
  }
  get hostElement() {
    return this;
  }
  static get style() {
    return OwcCookieAcceptanceOptionStyle0;
  }
}, [6, "owc-cookie-acceptance-option", {
  "name": [513],
  "disabled": [516],
  "accepted": [1540]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-cookie-acceptance-option", "owc-switch"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-cookie-acceptance-option":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, CookieAcceptanceOption);
        }
        break;
      case "owc-switch":
        if (!customElements.get(tagName)) {
          defineCustomElement$2();
        }
        break;
    }
  });
}
const OwcCookieAcceptanceOption = CookieAcceptanceOption;
const defineCustomElement = defineCustomElement$1;
export { OwcCookieAcceptanceOption, defineCustomElement };