import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializerProvider } from './core/app-initializer/app-initializer.service';
import { ENVIRONMENT } from './core/models/environment.token';
import { TranslocoRootModule } from './transloco-root.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppUtils } from './features/utils/app-utils';
import { DOCUMENT } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [BrowserModule, AppRoutingModule, TranslocoRootModule],
  providers: [
    AppUtils,
    appInitializerProvider,
    ...environment.providers,
    { provide: ENVIRONMENT, useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})

export class AppModule {
  public constructor(@Inject(DOCUMENT) private document: Document) {
    const urlQueryString = this.document.location.search;
    const parameters = new URLSearchParams(urlQueryString);
    const storeUrlData = sessionStorage.getItem('urlParameters');
    const publicKeyTokenvalue = parameters.get('publicKeyToken') || this.getUrlVar(storeUrlData, 'publicKeyToken');

    if (parameters.get('publicKeyToken') || parameters.get('notificationId')) {
      sessionStorage.setItem('urlParameters', parameters.toString());
    }
    if (publicKeyTokenvalue && publicKeyTokenvalue !== 'undefined') {
      localStorage.setItem('userType', 'isNonDialogUser');
    } else {
      localStorage.setItem('userType', 'isNotLoginDialogUser');
    }
  }
  
  public getUrlVar(urlString: any, key: any): any {
    const result = new RegExp(key + '=([^&]*)', 'i').exec(urlString);
    return (result && unescape(result[1])) || '';
  }
}
