<div class="page-content">
    <div *ngIf="!notificationDetailsObj || spinBool" class="spinner-overlay">
        <owc-progress-spinner size="xxLarge" value="50" />
    </div>
    <div class="topbar-container" *ngIf="notificationDetailsObj">
        <div>
            <owc-card oneCard class="cd-card-customer">
                <div class="cd-mat-card">
                    <div class="cd-feedback-title truncate-text">{{notificationDetailsObj.LocalId}}</div>
                    <owc-chip 
                    [ngClass]="appUtil.getColor(notificationDetailsObj.CustomerStatus || notificationDetailsObj.Status)"
                    [title]="(notificationDetailsObj.CustomerStatus || notificationDetailsObj.Status) | transloco"
                    >
                        {{ (notificationDetailsObj.CustomerStatus || notificationDetailsObj.Status) | transloco }}
                    </owc-chip>
                    <owc-chip 
                    *ngIf="accSelector === 'My notifications' && notificationDetailsObj.Submitter !== ContactNotificationObj.CustomerContactEmail && notificationDetailsObj.Submitter !== undefined" 
                    title="{{notificationDetailsObj.Submitter}}"
                    style="background-color: #f5f5f2;"
                    >
                        Colleague
                    </owc-chip>
                </div>
                <hr class="horizontal-line">
                <div *ngIf="remindersData.length > 0 && remindersData[0].ReminderSendDate" class="cd-feedback-list" >
                    <div class="cd-reminder-title">
                        <owc-expandable  control-placement="right">
                            <div slot="title">
                                {{ 'Reminder history' | transloco }}
                            </div>
                            <div slot="content">
                                <div>
                                    <div>{{ remindersData[0].ReminderSubject }} <span style="float:right;" [title]="remindersData[0].ReminderSendDate">{{ appUtil.formatDateOnly(remindersData[0].ReminderSendDate) }}</span></div>
                                    <div [innerHTML]="remindersData[0].ReminderMessage"></div>
                                    <hr>
                                </div>
                                <div  *ngFor="let reminder of remindersData; let i = index; let last = last">
                                    <ng-container *ngIf="reminder.ReminderSendDate && i !== 0">
                                        <div>{{ reminder.ReminderSubject }} <span style="float:right;" [title]="reminder.ReminderSendDate">{{ appUtil.formatDateOnly(reminder.ReminderSendDate) }}</span></div>
                                        <div [innerHTML]="reminder.ReminderMessage"></div>
                                        <hr *ngIf="!last">
                                    </ng-container>
                                </div>
                            </div>
                        </owc-expandable>
                    </div>
                    <hr class="horizontal-line">
                </div>
                <div>
                    <div class="cd-feedback-list" >
                        <div class="cd-feedback-list-title" *ngIf="customerDataObj.MessageTitle && customerDataObj.MessageTitle.length > 0">{{ customerDataObj.MessageTitle }}</div>
                        <div class="cd-feedback-list-subject" *ngIf="customerDataObj.MessageBody">
                            <div [innerHTML]="customerDataObj.MessageBody"></div>
                        </div>
                    </div>
                    <hr class="horizontal-line">
                    <div class="cd-feedback-list" *ngIf="customerDataObj.NotificationAttachments && customerDataObj.NotificationAttachments.length > 0">
                        <div class="cd-feedback-list-title" > {{ 'Attachments' | transloco }} </div>
                        <div class="file-upload-multiple">
                        <!--Local Attachment-->
                            <div *ngFor="let attachmentData of customerDataObj.NotificationAttachments">
                                <div class="cd-files-list" *ngIf="customerDataObj.NotificationAttachments && customerDataObj.NotificationAttachments.length > 0">
                                    <div class="single-file">
                                        <div class="info" (click)="downloadAttachment(attachmentData.FileName, customerDataObj.NotificationId)">
                                            <owc-icon class="one-icons iconcls">{{ appUtil.formatFilesIcons(attachmentData.FileName) }}</owc-icon>
                                            <span style="margin-left:10px">
                                            <span class="name">
                                                {{ attachmentData.FileName }}
                                            </span>
                                            <p class="size">{{ formatBytes(attachmentData.FileSize) }}</p></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!--Local Attachment end-->
                        </div>
                        <hr class="horizontal-line" />
                    </div>
                    <div class="cd-feedback-list">
                        <div class="cd-feedback-list-title" *ngIf="customerDataObj.CustomerFeedbackItems && customerDataObj.CustomerFeedbackItems.length > 0" > Feedback </div>
                        <!--For FeedBack Submit-->
                        <form [formGroup]="feedbackDataForm">
                            <div *ngIf="accSelector  === 'My notifications'">
                                <div *ngIf="(isFeedBackSubmitted || isCompleted) && (detailsAPIRes.ContactNotification[0].CustomerFeedbackResponseItems || detailsAPIRes.AccountNotification[0].FeedbackResponseItems); else feedbakNotSubmitted" class="cd-feedback-list" >
                                    <div *ngFor="let feedbackdatas of customerDataObj.CustomerFeedbackItems">   
                                        <div *ngFor="let responseres of (detailsAPIRes.ContactNotification[0].CustomerFeedbackResponseItems || detailsAPIRes.AccountNotification[0].FeedbackResponseItems)" class="feedbackContaier">
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Checkbox' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <section class="example-section">
                                                    <p class="checkbox-p">
                                                        <owc-checkbox type="checkbox" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [value]="responseres.FeedbackResponseItemLabel" >
                                                           <span class="wrap">
                                                               {{ feedbackdatas.FeedbackItemLabel }}
                                                           </span>
                                                        </owc-checkbox>
                                                        <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" class="spanOpt">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                    </p>
                                                </section>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Question' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <div>
                                                    {{ feedbackdatas.FeedbackItemLabel }}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </div>
                                                <owc-input type="text" class="cd-feedback-list-answer" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" placeholder="Your answer"   [value]="responseres.FeedbackResponseItemLabel"/>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Dropdown' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                <div>
                                                    <form>
                                                        <owc-select-dropdown style="width: 45rem;" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [placeholder]="'Select...'|transloco"  [value]="responseres.FeedbackResponseItemLabel">
                                                            @for (opts of feedbackdatas.FeedbackItemDropdown; track $index) {
                                                                <owc-list-item [value]="opts">
                                                                    {{opts}}
                                                                </owc-list-item>
                                                            }
                                                            
                                                        </owc-select-dropdown>
                                                    </form>
                                                </div>
                                            </div>
                                            <!-- Radio Start -->
                                            <div  *ngIf="feedbackdatas.FeedbackItemType == 'Radio' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <!-- Header -->
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span  *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                <!-- Options -->
                                                <div>
                                                    <owc-radio-group
                                                    aria-labelledby="radio-group-label"
                                                    [formControlName]="getFormControl(feedbackdatas.FeebackItemId)"
                                                    class="radio-group"
                                                    [value]="responseres.FeedbackResponseItemLabel"
                                                    >
                                                        @for(optsRadio of feedbackdatas.FeedbackItemRadio; track $index){
                                                            <owc-radio class="radio-button" [value]="optsRadio">
                                                                <span class="wrap">
                                                                    {{ optsRadio }}
                                                                </span>
                                                            </owc-radio>
                                                        }
                                                    </owc-radio-group>
                                                </div>
                                            </div>
                                            <!-- Radio END -->
                                        </div>
                                    </div> 
                                </div>

                                <ng-template #feedbakNotSubmitted>
                                    <div class="cd-feedback-list">
                                        <div *ngFor="let feedbackdatas of customerDataObj.CustomerFeedbackItems">
                                            <div class="cd-feedback-list-checkbox" *ngIf="feedbackdatas.FeedbackItemType == 'Checkbox'">
                                                <section class="example-section">
                                                <p class="checkbox-p">
                                                    <owc-checkbox [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" >
                                                        <span class="wrap">
                                                            {{ feedbackdatas.FeedbackItemLabel }}
                                                        </span>
                                                    </owc-checkbox>
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" class="spanOpt">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                </section>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Question'">
                                                <div>{{ feedbackdatas.FeedbackItemLabel }}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </div>
                                                <input [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" class="cd-feedback-list-answer" placeholder="Your answer"  />
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Dropdown'">
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                <div>
                                                    <form>
                                                        <owc-select-dropdown style="width: 45rem;" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [placeholder]="'Select...'|transloco" >
                                                            @for (opts of feedbackdatas.FeedbackItemDropdown; track $index) {
                                                                <owc-list-item [value]="opts">
                                                                    {{opts}}
                                                                </owc-list-item>
                                                            }
                                                        </owc-select-dropdown>
                                                    </form>
                                                </div>
                                            </div>
                                            <!-- Radio Start -->
                                            <div  *ngIf="feedbackdatas.FeedbackItemType == 'Radio'">
                                                <!-- Header -->
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span  *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                <!-- Options -->
                                                <div>
                                                    <owc-radio-group
                                                    aria-labelledby="radio-group-label"
                                                    [formControlName]="getFormControl(feedbackdatas.FeebackItemId)"
                                                    class="radio-group"
                                                    >
                                                        @for(optsRadio of feedbackdatas.FeedbackItemRadio; track $index){
                                                            <owc-radio class="radio-button" [value]="optsRadio">
                                                                <span class="wrap"> 
                                                                    {{ optsRadio }}
                                                                </span>
                                                            </owc-radio>
                                                        }
                                                    </owc-radio-group>
                                                </div>
                                            </div>
                                            <!-- Radio END -->
                                        </div>
                                    </div>
                                </ng-template>
                            </div>

                            <div *ngIf="accSelector  !== 'My notifications'">
                                <div *ngIf="(isFeedBackSubmitted || isCompleted) 
                                    && (detailsAPIRes.ContactNotification[0].CustomerFeedbackResponseItems || detailsAPIRes.AccountNotification[0].FeedbackResponseItems); else feedbakNotSubmitted" class="cd-feedback-list" >
                                    <div *ngFor="let feedbackdatas of customerDataObj.CustomerFeedbackItems">   
                                        <div *ngFor="let responseres of (detailsAPIRes.ContactNotification[0].CustomerFeedbackResponseItems || detailsAPIRes.AccountNotification[0].FeedbackResponseItems)">
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Checkbox' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <section class="example-section">
                                                    <p class="checkbox-p">
                                                        <owc-checkbox type="checkbox" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [value]="responseres.FeedbackResponseItemLabel" >
                                                            <span class="wrap">
                                                                {{ feedbackdatas.FeedbackItemLabel }}
                                                            </span>
                                                        </owc-checkbox>
                                                        <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" class="spanOpt">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                    </p>
                                                </section>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Question' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <div>
                                                    {{ feedbackdatas.FeedbackItemLabel }}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </div>
                                                <owc-input type="text" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" class="cd-feedback-list-answer" placeholder="Your answer"   [value]="responseres.FeedbackResponseItemLabel"/>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Dropdown' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                <div>
                                                    <owc-select-dropdown style="width: 45rem;" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [placeholder]="'Select...'|transloco"  [value]="responseres.FeedbackResponseItemLabel">
                                                        @for(opts of feedbackdatas.FeedbackItemDropdown; track $index){
                                                            <owc-list-item [value]="opts">
                                                                {{opts}}
                                                            </owc-list-item>
                                                        }
                                                    </owc-select-dropdown>
                                                </div>
                                            </div>
                                            <!-- Radio Start -->
                                            <div  *ngIf="feedbackdatas.FeedbackItemType == 'Radio' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <!-- Header -->
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span  *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                <!-- Options -->
                                                <div >
                                                    <owc-radio-group
                                                    aria-labelledby="radio-group-label"
                                                    [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" 
                                                    class="radio-group"
                                                    [value]="responseres.FeedbackResponseItemLabel"
                                                    >
                                                        @for(optsRadio of feedbackdatas.FeedbackItemRadio; track $index){
                                                            <owc-radio class="radio-button" [value]="optsRadio">
                                                                <span class="wrap">
                                                                    {{ optsRadio }}
                                                                </span>
                                                            </owc-radio>
                                                        }
                                                    </owc-radio-group>
                                                </div>
                                            </div>
                                            <!-- Radio END -->
                                        </div>
                                    </div> 
                                </div>

                                <ng-template #feedbakNotSubmitted>
                                    <div class="cd-feedback-list">
                                        <div *ngFor="let feedbackdatas of customerDataObj.CustomerFeedbackItems">
                                            <div class="cd-feedback-list-checkbox" *ngIf="feedbackdatas.FeedbackItemType == 'Checkbox'">
                                                <section class="example-section">
                                                <p class="checkbox-p">
                                                    <owc-checkbox [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" >
                                                        <span class="wrap">
                                                            {{ feedbackdatas.FeedbackItemLabel }}
                                                        </span>
                                                    </owc-checkbox>
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" class="spanOpt">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                </section>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Question'">
                                                <div>{{ feedbackdatas.FeedbackItemLabel }}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </div>
                                                <owc-input [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" class="cd-feedback-list-answer" placeholder="Your answer"  />
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Dropdown'">
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                <div>
                                                    <owc-select-dropdown style="width: 45rem;" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [placeholder]="'Select...'|transloco" >
                                                        @for(opts of feedbackdatas.FeedbackItemDropdown; track $index){
                                                            <owc-list-item [value]="opts">
                                                                {{opts}}
                                                            </owc-list-item>
                                                        }
                                                    </owc-select-dropdown>
                                                </div>
                                            </div>
                                            <!-- Radio Start -->
                                            <div  *ngIf="feedbackdatas.FeedbackItemType == 'Radio'">
                                                <!-- Header -->
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span  *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" >{{feedbackdatas.FeedbackItemMandatory | transloco}}</span>)</span>
                                                </p>
                                                <!-- Options -->
                                                <div>
                                                    <owc-radio-group 
                                                    class="radio-group"
                                                    aria-labelledby="radio-group-label"
                                                    [formControlName]="getFormControl(feedbackdatas.FeebackItemId)"  
                                                    >
                                                        @for(optsRadio of feedbackdatas.FeedbackItemRadio; track $index){
                                                            <owc-radio class="radio-button" [value]="optsRadio">
                                                                <span class="wrap">
                                                                    {{ optsRadio }}
                                                                </span>
                                                            </owc-radio>
                                                        }
                                                    </owc-radio-group>
                                                </div>
                                            </div>
                                            <!-- Radio END -->
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                            <!--FeedBack Form end-->
                            <div class="cd-feedback-list-actionbutton">
                                <owc-button variant="secondary" *ngIf="!isFeedBackSubmitted && !isCompleted" (click)="Oncancel()" class='cnl-btn' >{{'Cancel' | transloco}}</owc-button>
                                <owc-button *ngIf="!isFeedBackSubmitted && !isCompleted && !isNonDialogSBNType" [disabled]="feedbackDataForm.invalid" (click)="SubmitFeedback()" >{{'Submit' | transloco}}</owc-button>
                                <owc-button *ngIf="!isFeedBackSubmitted && !isCompleted && isNonDialogSBNType" [disabled]="feedbackDataForm.invalid" (click)="nonDialogConfirmFeedback()" >{{'Confirm' | transloco}}</owc-button>
                                <owc-button *ngIf="isFeedBackSubmitted || isCompleted" (click)="OnBack()" >{{'Back' | transloco}}</owc-button>
                            </div>
                        </form>
                    </div>
                </div>
            </owc-card>
        </div>
    </div>
    <div class="mat-error data-error" *ngIf="isApIFailed">
        {{ msgAPIFailed | transloco }}
    </div>
</div>