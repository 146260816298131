import { OneWebComponentsAngularModule } from '@one/angular';
import { ChangeDetectionStrategy, Inject, Component, ChangeDetectorRef, OnInit, ViewEncapsulation, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerNotificationService } from '../main-page/customer-notification.service';
import { AppUtils } from '../utils/app-utils';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { snackbarService } from '@one/web-components';
import { TranslocoModule } from '@jsverse/transloco';
import { NgIf } from '@angular/common';
import { watchAllRegions } from 'shell-browser/region.js';


@Component({
  selector: 'app-customer-contact-details',
  standalone: true,
  imports: [OneWebComponentsAngularModule, ReactiveFormsModule, TranslocoModule, NgIf],
  templateUrl: './customer-contact-details.component.html',
  styleUrl: './customer-contact-details.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerContactDetailsComponent implements OnInit, AfterViewInit {
  public countryList = [];
  public contactDataForm: FormGroup;
  public feedbackSubmittedData;
  public notificationData;
  public constructor(
    public _changeDetect: ChangeDetectorRef, 
    public renderer: Renderer2, 
    public fb: FormBuilder, 
    public appUtil: AppUtils, 
    public router: Router, 
    public customerservice: CustomerNotificationService, 
    public activateroute: ActivatedRoute
  ) {
    this.feedbackSubmittedData = history.state.feedbackData;
    this.notificationData = history.state.notificationData;
  }

  public ngOnInit(): void {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ .]{2,30}$/;
    const middleNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ .]{1,30}$/;
    this.contactDataForm = this.fb.group({
      fcCountry: ['', Validators.required],
      fcEmail: [null, [Validators.required, Validators.pattern(emailRegex)]],
      fcFirstName: [null, [Validators.required, Validators.pattern(nameRegex)]],
      fcMiddleName: [null, Validators.pattern(middleNameRegex)],
      fcLastName: [null, [Validators.required, Validators.pattern(nameRegex)]]
    });

    watchAllRegions(
      (regions) => {
        regions.forEach( i => {
          this.countryList.push(i.description);
        });
      }
    )
  }
  
  public ngAfterViewInit(): void {
    const localId = this.notificationData ? this.notificationData.LocalId : '';
    this.customerservice.setLocalId(localId);
  }

  public Oncancel(): void {
    const urlData = sessionStorage.getItem('urlParameters');
    const url = `/customer-notification-details?${urlData}`;
    this.router.navigateByUrl(url);
  }
  public SubmitFeedback(): void {
    sessionStorage.removeItem('urlParameters');
    const contactsData = this.contactDataForm.value;
    const middleName = contactsData.fcMiddleName ? contactsData.fcMiddleName.trim() : '';
    const contactDetailsObj = {
      CustomerAddedCountry: contactsData.fcCountry[0].trim(),
      CustomerAddedEmail: contactsData.fcEmail.trim(),
      CustomerAddedFirstName: contactsData.fcFirstName.trim(),
      CustomerAddedMiddleName: middleName,
      CustomerAddedLastName: contactsData.fcLastName.trim()
    };
    const feedbackResponse = {
      ...this.feedbackSubmittedData,
      ...contactDetailsObj
    };
    this.customerservice.putService(feedbackResponse).subscribe(
      (response: HttpResponse<any>) => {
        this.customerservice.storeNotificationTableData = null;
        const feedbackSubmitted = this.appUtil.getTranslatedMessage('FeedbackSubmitted');
        snackbarService.show({
          message: feedbackSubmitted, 
          snackbarProps: {
            status: 'positive',
            direction: 'horizontal',
            showClose: true,
            elevated: false,
            duration: 5000,
            verticalPlacement: 'top',
            horizontalPlacement: 'right',
          }
        });
        const _self = this;
        setTimeout(function(){
          _self.router.navigateByUrl('/welcome', { state: { notificationData: this.notificationData } });
        }, 5000);
      },
      (error: HttpErrorResponse) => {
        const feedbackFailed = this.appUtil.getTranslatedMessage('FeedbackFailed');
        snackbarService.show({
          message: feedbackFailed, 
          snackbarProps: {
            status: 'alarm',
            direction: 'horizontal',
            showClose: true,
            elevated: false,
            duration: 5000,
            verticalPlacement: 'top',
            horizontalPlacement: 'right',
          }
        });
      }
    );
  }
}
