import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-25bf8c52.js';
import './p-604842db.js';
import { b as accessibility } from './p-756e7dc5.js';
import { J as KeydownManager, L as KeydownTemplate, F as FocusType, H as KeydownLifecycleMethod, p as findElementInEventPath, o as stopImmediatePropagation, s as stopEvent } from './p-a00c815e.js';
import { a as ComponentUtils } from './p-cf58d171.js';
import { O as OnClosestFormReset } from './p-31a38526.js';
const radioGroupCss = ".sc-owc-radio-group-h{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;outline:none;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.owc-radio-group--disabled.sc-owc-radio-group-h{pointer-events:none}.owc-radio-group--direction-horizontal.sc-owc-radio-group-h{display:grid;width:100%;grid-template-columns:1fr;grid-auto-columns:1fr;grid-auto-flow:column}";
const OwcRadioGroupStyle0 = radioGroupCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const RADIO_ITEM = 'owc-radio';
const NATIVE_INPUT_ELEMENT = 'input';
const RadioGroup = /*@__PURE__*/proxyCustomElement(class RadioGroup extends H {
  constructor() {
    super();
    this.__registerHost();
    this.valueChange = createEvent(this, "valueChange", 3);
    this.setValue = newValue => {
      if (newValue !== this.value) {
        if (this.controlled) {
          this.valueChange.emit(newValue);
        } else {
          this.value = newValue;
        }
      }
    };
    this.getItems = () => [...Array.from(this.hostElement.querySelectorAll(RADIO_ITEM))];
    this.getSelectableItems = () => this.disabled ? [] : this.getItems().filter(item => !item.disabled);
    this.propagateAttributesToItems = () => {
      this.getItems().forEach(el => {
        el.controlled = this.controlled;
        el.checked = el.value === this.value;
        el.groupDisabled = this.disabled;
        el.nativeInputProps = Object.assign(Object.assign({}, el.nativeInputProps), {
          tabIndex: -1
        });
      });
    };
    this.component = new ComponentUtils(this).withKeydownManager([KeydownManager.Templates[KeydownTemplate.GROUP], {
      focusType: FocusType.BY_CLASS,
      selector: () => this.getSelectableItems(),
      actualSelectedItem: () => this.getSelectableItems().find(item => item.value === this.value),
      lifecycleHandlers: {
        [KeydownLifecycleMethod.ITEM_CHANGE]: ({
          items,
          current
        }) => {
          var _a, _b, _c;
          this.activeRadioButtonId = ((_b = (_a = items[current]) === null || _a === void 0 ? void 0 : _a.querySelector(NATIVE_INPUT_ELEMENT)) === null || _b === void 0 ? void 0 : _b.id) || undefined;
          (_c = this.hostElement) === null || _c === void 0 ? void 0 : _c.focus();
        }
      }
    }]);
    this.name = undefined;
    this.direction = 'vertical';
    this.controlled = false;
    this.value = undefined;
    this.disabled = false;
    this.activeRadioButtonId = undefined;
  }
  onValueChange(newValue, oldValue) {
    newValue !== oldValue && !this.controlled && this.valueChange.emit(newValue);
  }
  onRadioValueChange(event) {
    const item = findElementInEventPath(event, RADIO_ITEM);
    if (item) {
      stopImmediatePropagation(event);
      stopEvent(event);
      event.detail && this.setValue(item.value);
    }
  }
  onListItemClick(event) {
    const item = findElementInEventPath(event, RADIO_ITEM);
    if (item && !item.disabled) {
      stopEvent(event);
      this.hostElement.focus();
      this.setValue(item.value);
    }
  }
  onClosestFormReset() {
    this.setValue(this.defaultValue);
  }
  componentDidLoad() {
    var _a, _b;
    this.defaultValue = this.value;
    this.activeRadioButtonId = (_b = (_a = this.getSelectableItems().find(e => e.checked) || this.getSelectableItems()[0]) === null || _a === void 0 ? void 0 : _a.querySelector(NATIVE_INPUT_ELEMENT)) === null || _b === void 0 ? void 0 : _b.id;
    this.propagateAttributesToItems();
  }
  componentWillRender() {
    // TODO: trigger this from watcher and MutationObserver
    this.propagateAttributesToItems();
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      disabled: this.disabled,
      [`direction-${this.direction || 'vertical'}`]: true
    });
    return h(Host, Object.assign({
      key: '6622b1bc9c2cb2c764e10ce7302d87bb9e34b661',
      tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex(0),
      class: hostClasses
    }, accessibility({
      role: 'radiogroup',
      aria: {
        disabled: !!this.disabled,
        activedescendant: this.activeRadioButtonId
      }
    })), h("slot", {
      key: '6ecc4faa62e40fd46b48a628e53ac8a459e9d5ea'
    }));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "value": ["onValueChange"]
    };
  }
  static get style() {
    return OwcRadioGroupStyle0;
  }
}, [6, "owc-radio-group", {
  "name": [513],
  "direction": [513],
  "controlled": [516],
  "value": [1537],
  "disabled": [516],
  "activeRadioButtonId": [32]
}, [[2, "valueChange", "onRadioValueChange"], [0, "click", "onListItemClick"]], {
  "value": ["onValueChange"]
}]);
__decorate([OnClosestFormReset()], RadioGroup.prototype, "onClosestFormReset", null);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-radio-group"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-radio-group":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, RadioGroup);
        }
        break;
    }
  });
}
export { RadioGroup as R, defineCustomElement as d };