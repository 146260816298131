
<owc-table>
  @if(spinBool){
    <div class="spinner-overlay">
      <owc-progress-spinner size="xxLarge" value="50" />
    </div>
  }
  <owc-table-header sticky>
      <owc-table-header-cell sortable [align]="'left'" (sortOrderChange)="OnSort('Type', $event.detail)">{{ 'Type' | transloco }}</owc-table-header-cell>
      <owc-table-header-cell sortable [align]="'left'" (sortOrderChange)="OnSort('LocalId', $event.detail)">ID</owc-table-header-cell>
      <owc-table-header-cell sortable [align]="'left'" (sortOrderChange)="OnSort('Title', $event.detail)">{{ 'Title' | transloco }}</owc-table-header-cell>
      <owc-table-header-cell sortable [align]="'left'" (sortOrderChange)="OnSort('CustomerName', $event.detail)">{{ 'Account Name' | transloco }}</owc-table-header-cell>
      <owc-table-header-cell sortable [align]="'left'" (sortOrderChange)="OnSort('DateDelivered', $event.detail)">{{ 'Date Received' | transloco }}</owc-table-header-cell>
      <owc-table-header-cell sortable [align]="'left'" (sortOrderChange)="OnSort('DeadlineFeedback', $event.detail)">{{ 'Deadline Feedback' | transloco }}</owc-table-header-cell>
      <owc-table-header-cell sortable [align]="'left'" (sortOrderChange)="OnSort('Status', $event.detail)">{{ 'Status' | transloco }}</owc-table-header-cell>
  </owc-table-header>

  <owc-table-body>
    @if(tableData.length > 0){
      @for (item of tableData; track $index) {
        <owc-table-row locked (click)="OpenNotificationDetails(item)">

          <owc-table-simple-cell [title]="item.Type">
            <div class="truncate-text">
              {{ item.Type }}
            </div>
          </owc-table-simple-cell>

          <owc-table-simple-cell [title]="item.LocalId">
            <div class="truncate-text">
              {{ item.LocalId }}
            </div>
          </owc-table-simple-cell>

          <owc-table-simple-cell [title]="item.Title">
            <div class="truncate-text">
              {{ item.Title }}
            </div>
          </owc-table-simple-cell>

          <owc-table-simple-cell [title]="item.CustomerName || item.AccountName">
            <div class="truncate-text">
              {{ item.CustomerName || item.AccountName }}
            </div>
          </owc-table-simple-cell>

          <owc-table-simple-cell [title]="item.DateDelivered">
            <div class="truncate-text">
              {{ appUtils.formatDateOnly(item.DateDelivered) }}
            </div>
          </owc-table-simple-cell>

          <owc-table-simple-cell [title]="item.FeedbackDueDate">
            <div class="truncate-text">
              {{ appUtils.formatDateOnly(item.FeedbackDueDate) }}
            </div>
          </owc-table-simple-cell>

          <owc-table-simple-cell style="display: flex; gap: 10px; align-items: anchor-center">
            
            <div style="display: flex; flex-direction: column; gap: 10px;">

              <owc-chip  
              [ngClass]="appUtils.getColor(item.CustomerStatus || item.Status)"  
              [title]="(item.CustomerStatus || item.Status) | transloco">
                <span class="ellipsis">
                  {{ (item.CustomerStatus || item.Status) | transloco }}
                </span>
              </owc-chip>

              <owc-chip 
              [hidden]="!(accSelected === 'My notifications' && item.Submitter !== item.CustomerContactEmail && item.Submitter !== undefined)"
              title="{{item.Submitter}}"
              style="background-color: #f5f5f2;"
              >
                Colleague
              </owc-chip>

            </div>
            
            <owc-icon name="chevron_right" family="outlined"></owc-icon>
          </owc-table-simple-cell>

        </owc-table-row>
      }
    }
  </owc-table-body>
</owc-table>

<div class="noDataView">
  @if(initPageLoad){
    <div>
      {{ 'No data available.' | transloco }}
    </div>
  }@else {
    @if(filterBool){
      <div>
        {{ 'No data available with given filter criteria.' | transloco }}
      </div>
    }
    @if(searchBool){
      <div>
        {{ 'No data available with given search criteria.' | transloco }}
      </div>
    }
    @if(tableData.length < 1 && !filterBool && !searchBool){
      {{ 'Fetching data...' | transloco }}
    }
  }
</div>
