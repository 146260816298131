import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-25bf8c52.js';
import { j as dateFns, g as locale } from './p-d9a8c87c.js';
import { i as isSameArray } from './p-d7a5de56.js';
import { z as hasValue, u as isAlphaNumeric, q as stringToNumberArray } from './p-604842db.js';
import { O as OnClosestFormSubmit, V as Validator, b as moveTimeToTodayUTC, i as isTimeEqual, m as moveTimeToToday, F as FORMAT_MAP, h as handleLocation, s as startOfDayUTC, e as endOfDayUTC, c as getTimeInterval, d as defineFormat } from './p-f54579bd.js';
import { h as hideElementFromAccessibilityAPI, a as accessibilityAttributes } from './p-756e7dc5.js';
import { K as KeyboardCode, v as doWhenDefaultIsNotPrevented, s as stopEvent, G as KeydownHostEvent, J as KeydownManager, E as KeydownAction, p as findElementInEventPath } from './p-a00c815e.js';
import { u as useRef } from './p-e952b680.js';
import { a as ComponentUtils } from './p-cf58d171.js';
import { O as OnClosestFormReset } from './p-31a38526.js';
import { d as defineCustomElement$e } from './p-43e98172.js';
import { d as defineCustomElement$d } from './p-c922fcf3.js';
import { d as defineCustomElement$c } from './p-bed53612.js';
import { d as defineCustomElement$b } from './p-fc8ae07f.js';
import { d as defineCustomElement$a } from './p-2977eb6f.js';
import { d as defineCustomElement$9 } from './p-1be53978.js';
import { d as defineCustomElement$8 } from './p-8495f6f6.js';
import { d as defineCustomElement$7 } from './p-fa64486b.js';
import { d as defineCustomElement$6 } from './p-744a991c.js';
import { d as defineCustomElement$5 } from './p-fe94825f.js';
import { d as defineCustomElement$4 } from './p-ddd9f091.js';
import { d as defineCustomElement$3 } from './p-e9171c24.js';
import { d as defineCustomElement$2 } from './p-7708f872.js';
const timepickerCss = ".sc-owc-timepicker-h{width:calc(var(--one-spacing-size-7-xs) * 250)}.sc-owc-timepicker-h owc-input.sc-owc-timepicker{min-width:inherit !important;max-width:inherit !important;width:inherit !important}.sc-owc-timepicker-h owc-input.sc-owc-timepicker input.sc-owc-timepicker{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:100%}.sc-owc-timepicker-h owc-menu.sc-owc-timepicker{-webkit-box-shadow:none;box-shadow:none}";
const OwcTimepickerStyle0 = timepickerCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = undefined && undefined.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
const DEFAULT_STEP = 60;
const TAG_LIST_ITEM = 'owc-list-item';
const PAGE_JUMP_OFFSET = 10;
const Timepicker = /*@__PURE__*/proxyCustomElement(class Timepicker extends H {
  constructor() {
    super();
    this.__registerHost();
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.valueChange = createEvent(this, "valueChange", 3);
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.validityChange = createEvent(this, "validityChange", 3);
    this.clearClick = createEvent(this, "clearClick", 3);
    this.inputValueChange = createEvent(this, "inputValueChange", 3);
    this.resetRequested = false;
    this.focusedProgrammatically = false;
    this.selectableTimesFormatted = [];
    this.inputRef = useRef();
    this.validator = new Validator();
    this.referenceDate = moveTimeToTodayUTC(dateFns.set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    }), Date.now());
    this.nativeInputRef = useRef();
    this.clearButtonRef = useRef();
    this.listRef = useRef();
    this.updateDropdownFocus = () => {
      var _a;
      (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.getKeydownManager().then(manager => {
        if (!this.focusedProgrammatically) {
          manager.clearFocus();
          const item = manager.getActualSelectedItem();
          if (item) {
            manager.setCurrentItem(item, null, false);
          } else {
            const focusedIndex = this.getCurrentTimeIndex();
            focusedIndex >= 0 ? manager.setCurrentItem(this.getListItems()[focusedIndex], null, false) : manager.setCurrentIndex(0);
          }
        }
        this.focusedProgrammatically = false;
        manager.scrollToCurrentElement();
      });
    };
    this.focusSelectedItemInList = props => {
      var _a;
      (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.getKeydownManager().then(manager => {
        manager.clearFocus();
        const item = manager.getActualSelectedItem();
        if (item) {
          manager.setCurrentItem(item, props, true);
        } else {
          const focusedIndex = this.getCurrentTimeIndex();
          focusedIndex >= 0 ? manager.setCurrentItem(this.getListItems()[focusedIndex], props, true) : manager.setCurrentIndex(0);
        }
      });
    };
    this.setValue = newValue => {
      if (!isTimeEqual(newValue, this.value)) {
        if (this.controlled) {
          this.valueChange.emit(newValue);
        } else {
          this.value = newValue;
        }
      }
    };
    this.isInDisableList = time => this.getUnavailableDates().some(dt => {
      const dateUTC = moveTimeToToday(new Date(dt), this.referenceDate);
      return isTimeEqual(dateUTC, time);
    });
    this.focusInputElement = () => {
      var _a, _b;
      if (!this.disabled && !this.focused) {
        (_b = (_a = this.inputRef.current) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
        this.focused = true;
      }
    };
    this.blurInputElement = () => {
      var _a, _b;
      !this.disableValidation && this.hasTrigger('blur') && this.validate();
      if (this.focused) {
        (_b = (_a = this.inputRef.current) === null || _a === void 0 ? void 0 : _a.blur) === null || _b === void 0 ? void 0 : _b.call(_a);
        this.focused = false;
      }
    };
    this.getTriggers = () => {
      var _a;
      return Array.isArray(this.validationTrigger) ? this.validationTrigger : ((_a = this.validationTrigger) === null || _a === void 0 ? void 0 : _a.split(',').map(item => item.trim())) || [];
    };
    this.hasTrigger = trigger => this.getTriggers().includes(trigger);
    this.getListItems = () => {
      var _a;
      return Array.from(((_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll(TAG_LIST_ITEM)) || []);
    };
    this.getSearchableListItems = () => this.getListItems().filter(it => it.leaf && hasValue(it.value));
    this.getFormat = () => this.native ? `${FORMAT_MAP.hour24}:${FORMAT_MAP.minute}` : this.format;
    this.formatDate = (val = this.value, fallback = '') => val && dateFns.isValid(val) ? dateFns.format(dateFns.addMinutes(val, new Date().getTimezoneOffset()), this.getFormat(), {
      locale: handleLocation(this.native, this.locale)
    }) : fallback;
    this.parseDate = text => {
      if (!dateFns.isMatch(text, this.getFormat(), {
        locale: handleLocation(this.native, this.locale)
      })) {
        return null;
      }
      const parsedDate = dateFns.subMinutes(dateFns.parse(text, this.getFormat(), this.referenceDate, {
        locale: handleLocation(this.native, this.locale)
      }), new Date().getTimezoneOffset());
      if (!dateFns.isValid(parsedDate)) {
        return null;
      }
      if (!this.value || !isTimeEqual(parsedDate, this.value)) {
        return parsedDate;
      } else {
        return this.value;
      }
    };
    this.getCurrentTimeIndex = () => {
      const currentDate = new Date();
      const minutes = this.value ? dateFns.getMinutes(this.value) : dateFns.getMinutes(currentDate);
      const ceilMinute = Math.ceil(minutes / this.step) * this.step;
      const utcTime = dateFns.addMinutes(dateFns.startOfHour(this.value || currentDate), ceilMinute);
      const localTime = dateFns.subMinutes(utcTime, utcTime.getTimezoneOffset());
      return this.timeList.findIndex(time => isTimeEqual(time, localTime) && !this.isInDisableList(time));
    };
    this.onInputClicked = (ev, originalHandler) => {
      this.visible = true;
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onInputFocused = (ev, originalHandler) => {
      this.hostElement.dispatchEvent(new KeyboardEvent('focus'));
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onInputBlurred = (ev, originalHandler) => {
      this.hostElement.dispatchEvent(new KeyboardEvent('blur'));
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onInputValueChanged = (ev, originalHandler) => {
      this.inputText = ev.detail;
      this.updateValueFromInputText();
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onInputKeyDown = (ev, originalHandler) => {
      if (this.visible && this.allItemsHidden && [KeyboardCode.Enter, KeyboardCode.NumpadEnter].includes(ev.code)) {
        this.visible = false;
      }
      if (ev.key.length === 1 && isAlphaNumeric(ev.key) || [KeyboardCode.Backspace, KeyboardCode.Delete].includes(ev.code)) {
        this.visible = true;
        this.isSearching = true;
      }
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onMenuClickOutside = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      doWhenDefaultIsNotPrevented(ev, () => {
        this.visible = false;
        this.focused = false;
        this.clickOutside.emit();
      });
    };
    this.onClearButtonClicked = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      doWhenDefaultIsNotPrevented(ev, () => {
        var _a, _b;
        stopEvent(ev);
        if (this.visible) {
          this.searchTerm = '';
          this.isSearching = true;
        }
        this.inputText = '';
        this.setValue(null);
        this.clearClick.emit();
        !this.disableValidation && this.hasTrigger('blur') && this.validate();
        this.visible && (this.visible = false);
        (_b = (_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.focus();
      });
    };
    this.onListSelectedChanged = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      doWhenDefaultIsNotPrevented(ev, () => {
        const timestamp = ev === null || ev === void 0 ? void 0 : ev.detail[0];
        const dateText = this.getDateTextFromTimestamp(timestamp);
        dateText && this.setValue(this.parseDate(dateText));
        if (this.value && dateFns.isValid(this.value)) {
          this.inputText = this.formatDate(this.value);
        } else {
          this.inputText = '';
        }
        stopEvent(ev);
      });
    };
    this.component = new ComponentUtils(this, {
      patchFocusMethod: () => this.focusInputElement(),
      patchBlurMethod: () => this.blurInputElement()
    }).withKeydownManagerLazy(() => this.inputRef.current, {
      scroll: {
        container: () => this.listRef.current
      },
      hostEventHandlers: {
        [KeydownHostEvent.FOCUS]: () => {
          this.focused = true;
        }
      },
      keydownHandlers: [{
        key: [KeyboardCode.Tab, {
          code: KeyboardCode.Tab,
          shift: true
        }],
        handler: () => {
          !this.visible && !this.disableValidation && this.hasTrigger('blur') && this.validate();
          this.visible && (this.visible = false);
          this.focused = false;
        },
        preventDefault: false
      }, {
        key: KeydownManager.DefaultKeys[KeydownAction.CLOSE],
        disabled: () => !this.visible,
        handler: () => this.visible = false
      }, {
        key: [KeyboardCode.ArrowDown],
        handler: props => {
          var _a;
          if (this.visible) {
            (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new KeyboardEvent(props.event.type, props.event));
          } else {
            this.focusedProgrammatically = true;
            this.visible = true;
            this.focusSelectedItemInList(props);
          }
        }
      }, {
        key: [{
          code: KeyboardCode.ArrowDown,
          alt: true
        }],
        handler: () => {
          this.focusedProgrammatically = true;
          this.visible = true;
        },
        disabled: () => this.visible
      }, {
        key: [KeyboardCode.ArrowUp],
        handler: props => {
          var _a, _b;
          if (this.visible) {
            (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new KeyboardEvent(props.event.type, props.event));
          } else {
            this.focusedProgrammatically = true;
            this.visible = true;
            (_b = this.listRef.current) === null || _b === void 0 ? void 0 : _b.getKeydownManager().then(manager => manager.setCurrentItem(manager.getLastItem(), props));
          }
        }
      }, {
        key: [KeyboardCode.Home],
        handler: props => {
          var _a;
          (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.getKeydownManager().then(manager => manager.setCurrentItem(manager.getFirstItem(), props));
        },
        disabled: () => !this.visible,
        preventDefault: this.visible
      }, {
        key: [KeyboardCode.End],
        handler: props => {
          var _a;
          (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.getKeydownManager().then(manager => manager.setCurrentItem(manager.getLastItem(), props));
        },
        disabled: () => !this.visible,
        preventDefault: this.visible
      }, {
        key: [KeyboardCode.PageUp],
        handler: props => {
          var _a;
          (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.getKeydownManager().then(manager => manager.setCurrentItem(manager.getItem(manager.getCurrentIndex() - PAGE_JUMP_OFFSET, false), props));
        },
        disabled: () => !this.visible,
        preventDefault: this.visible
      }, {
        key: [KeyboardCode.PageDown],
        handler: props => {
          var _a;
          (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.getKeydownManager().then(manager => manager.setCurrentItem(manager.getItem(manager.getCurrentIndex() + PAGE_JUMP_OFFSET, false), props));
        },
        disabled: () => !this.visible,
        preventDefault: this.visible
      }, {
        key: [KeyboardCode.Enter, KeyboardCode.NumpadEnter],
        handler: props => {
          var _a, _b, _c;
          if (this.visible) {
            (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new KeyboardEvent(props.event.type, props.event));
            this.focused = false;
            this.visible = false;
            this.hostElement.focus();
          } else if (!((_c = (_b = this.clearButtonRef) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.contains(props.event.target))) {
            this.focusedProgrammatically = true;
            this.visible = true;
            this.focusSelectedItemInList(props);
          }
        }
      }]
    });
    this.name = undefined;
    this.disabled = false;
    this.format = 'hh:mm aaa';
    this.label = undefined;
    this.locale = locale.enUS;
    this.maxTime = undefined;
    this.minTime = undefined;
    this.native = false;
    this.noClearIcon = false;
    this.disableValidation = false;
    this.placeholder = undefined;
    this.required = false;
    this.selectOutOfStep = false;
    this.size = 'medium';
    this.controlled = false;
    this.step = DEFAULT_STEP;
    this.unavailable = [];
    this.validity = {
      state: 'valid'
    };
    this.validationTrigger = 'input, blur';
    this.controlledValidity = false;
    this.value = null;
    this.variant = 'filled';
    this.visible = false;
    this.inputProps = undefined;
    this.menuProps = undefined;
    this.selectAllCheckboxState = false;
    this.inputText = '';
    this.isSearching = false;
    this.searchTerm = undefined;
    this.allItemsHidden = undefined;
    this.minTimeVal = undefined;
    this.maxTimeVal = undefined;
    this.focused = false;
    this.timeList = [];
  }
  onValidatorParamsChange() {
    this.setValidationOptions();
    if (this.value) {
      this.updateInputTextFromValue();
      !this.disableValidation && this.validate();
    } else {
      this.updateValueFromInputText();
    }
  }
  setValidationOptions() {
    this.createMinMaxDates();
    this.generateListItems();
    this.formatSelectableTimes();
    this.validator.type('time').min(this.minTimeVal).max(this.maxTimeVal).pattern(this.getFormat()).locale(this.locale).notOneOf(this.unavailable).oneOf(this.selectableTimesFormatted);
    this.required ? this.validator.required() : this.validator.optional();
  }
  onValueChanged(newValue, oldValue) {
    if (!isTimeEqual(newValue, oldValue)) {
      if (!this.focused) {
        this.updateInputTextFromValue();
      }
      !this.controlled && this.valueChange.emit(newValue);
    }
  }
  onValidityChange(newValue, oldValue) {
    if ((newValue === null || newValue === void 0 ? void 0 : newValue.state) !== (oldValue === null || oldValue === void 0 ? void 0 : oldValue.state) || !isSameArray(oldValue === null || oldValue === void 0 ? void 0 : oldValue.type, newValue === null || newValue === void 0 ? void 0 : newValue.type)) {
      !this.controlledValidity && this.validityChange.emit(newValue);
      this.inputRef.current && (this.inputRef.current.validity = newValue);
    }
  }
  onClosestFormReset() {
    if (!this.disableValidation) {
      this.resetRequested = this.inputText !== '';
      this.controlledValidity ? this.validityChange.emit({
        state: 'valid'
      }) : this.validity = {
        state: 'valid'
      };
      this.setValue(null);
      this.inputText = '';
    }
  }
  onClosestFormSubmit() {
    this.resetRequested = false;
    !this.disableValidation && this.validate();
  }
  /** Triggers the validation */
  validate() {
    var _a, _b;
    if (this.resetRequested && this.hasTrigger('input')) {
      this.resetRequested = false;
      return Promise.resolve({
        state: 'valid'
      });
    }
    const validationResult = this.validator.validate(this.inputText);
    let newValidity;
    if (validationResult === null || validationResult === void 0 ? void 0 : validationResult.length) {
      newValidity = {
        state: 'error',
        type: validationResult
      };
    } else {
      newValidity = {
        state: 'valid'
      };
    }
    if ((newValidity === null || newValidity === void 0 ? void 0 : newValidity.state) !== ((_a = this.validity) === null || _a === void 0 ? void 0 : _a.state) || !isSameArray((_b = this.validity) === null || _b === void 0 ? void 0 : _b.type, newValidity.type)) {
      if (!this.controlledValidity) {
        this.validity = newValidity;
      } else {
        this.validityChange.emit(newValidity);
      }
    }
    return Promise.resolve(newValidity);
  }
  createMinMaxDates() {
    this.minTimeVal = this.minTime ? moveTimeToToday(new Date(this.minTime), this.referenceDate) : startOfDayUTC(this.referenceDate);
    this.maxTimeVal = this.maxTime ? moveTimeToToday(new Date(this.maxTime), this.referenceDate) : endOfDayUTC(this.referenceDate);
    // if min > max we default to start & end of the day
    if (this.minTimeVal >= this.maxTimeVal) {
      this.minTimeVal = startOfDayUTC(this.referenceDate);
      this.maxTimeVal = endOfDayUTC(this.referenceDate);
    }
  }
  onDisabledChanged() {
    this.visible = false;
  }
  onVisibleChanged(newValue, oldValue) {
    var _a;
    if (!newValue) {
      this.isSearching = false;
    } else {
      this.updateOptions('');
    }
    this.searchTerm = (_a = this.inputText) !== null && _a !== void 0 ? _a : undefined;
    !this.disableValidation && !newValue && this.hasTrigger('blur') && this.validate();
    if (!!newValue !== !!oldValue) {
      this.visibleChange.emit(!!newValue);
      newValue && this.updateDropdownFocus();
    }
  }
  onSearchTermChanged() {
    this.isSearching && this.updateOptions(this.searchTerm);
  }
  formatSelectableTimes() {
    const _selectableTimesFormatted = [];
    if (!this.selectOutOfStep) {
      getTimeInterval(this.step, this.minTimeVal, this.maxTimeVal).forEach(item => {
        const dateInMs = dateFns.getTime(item);
        !this.selectOutOfStep && _selectableTimesFormatted.push(dateInMs);
      });
    }
    this.selectableTimesFormatted = _selectableTimesFormatted;
  }
  updateInputTextFromValue() {
    if (this.value && dateFns.isValid(this.value)) {
      this.inputText = this.formatDate(this.value);
    } else {
      this.inputText = '';
    }
  }
  updateValueFromInputText() {
    if (!this.isSearching && this.inputText !== this.formatDate(this.value, '')) {
      this.isSearching = true;
    }
    this.isSearching && (this.searchTerm = this.inputText);
    !this.disableValidation && this.hasTrigger('input') && this.validate();
    this.setValue(this.parseDate(this.inputText));
    this.inputValueChange.emit(this.inputText);
  }
  componentDidLoad() {
    this.updateInputTextFromValue();
    this.setValidationOptions();
  }
  getDateTextFromTimestamp(timestamp) {
    if (dateFns.isValid(timestamp)) {
      const tz = new Date().getTimezoneOffset();
      return dateFns.format(dateFns.addMinutes(timestamp, tz), this.getFormat() || defineFormat(this.native, this.referenceDate), {
        locale: handleLocation(this.native, this.locale)
      });
    } else {
      return undefined;
    }
  }
  generateListItems() {
    this.timeList = getTimeInterval(this.step, this.minTimeVal, this.maxTimeVal);
    return this.timeList.map(item => {
      const dateText = this.getDateTextFromTimestamp(item);
      return dateText ? h("owc-list-item", {
        key: dateText,
        value: item,
        disabled: this.isInDisableList(item),
        onClick: e => {
          this.setValue(this.parseDate(dateText));
          const srcElement = findElementInEventPath(e, 'owc-list-item');
          (srcElement === null || srcElement === void 0 ? void 0 : srcElement.value) === this.value && (this.visible = false);
        }
      }, dateText) : null;
    }).filter(Boolean);
  }
  async updateOptions(input) {
    // TODO: debounce should be implemented for performance improvement
    const searchableItems = this.getSearchableListItems();
    const searchTerm = (input || '').toLowerCase();
    let hiddenCount = 0;
    let positionIndex = 0;
    for (const item of searchableItems) {
      let hide = false;
      if (searchTerm) {
        const searchKey = item.searchKey || (await item.getTextContent());
        hide = !searchKey.toLowerCase().includes(searchTerm);
      }
      item.hidden = hide;
      if (!hide) {
        positionIndex += 1;
        item.ariaPosInSet = `${positionIndex}`;
      } else {
        hiddenCount += 1;
      }
    }
    searchableItems.forEach(el => {
      el.ariaSetSize = `${searchableItems.length - hiddenCount}`;
    });
    this.allItemsHidden = hiddenCount && hiddenCount === searchableItems.length;
  }
  getUnavailableDates() {
    var _a;
    return stringToNumberArray(typeof this.unavailable === 'string' ? this.unavailable : (_a = this.unavailable) === null || _a === void 0 ? void 0 : _a.map(v => `${v}`));
  }
  getInputElement() {
    const _a = this.inputProps || {},
      {
        ref: inputRef
      } = _a,
      _b = _a.nativeInputProps,
      _c = _b === void 0 ? {} : _b,
      {
        ref: nativeInputRef = undefined
      } = _c,
      nativeInputProps = __rest(_c, ["ref"]),
      _d = _a.clearIconProps,
      _e = _d === void 0 ? {} : _d,
      {
        ref: clearIconRef = undefined,
        onclick: originalClearIconClick = undefined
      } = _e,
      clearIconProps = __rest(_e, ["ref", "onclick"]),
      {
        onfocus: originalOnInputFocus,
        onblur: originalOnInputBlur,
        onclick: originalOnInputClick,
        onValueChange: originalOnInputValueChange,
        onkeydown: originalOnInputKeyDown
      } = _a,
      inputProps = __rest(_a, ["ref", "nativeInputProps", "clearIconProps", "onfocus", "onblur", "onclick", "onValueChange", "onkeydown"]);
    return h("owc-input", Object.assign({
      name: this.name,
      ref: el => this.inputRef(el, [inputRef]),
      disabled: this.disabled,
      label: this.label,
      disableValidation: true,
      placeholder: this.placeholder,
      required: this.required,
      pattern: this.getFormat(),
      variant: this.variant,
      size: this.size,
      type: "time",
      validity: this.validity,
      noClearIcon: this.noClearIcon,
      nativeInputProps: Object.assign(Object.assign(Object.assign({
        ref: el => this.nativeInputRef(el, [nativeInputRef]),
        pattern: undefined,
        placeholder: undefined,
        role: 'combobox'
      }, accessibilityAttributes({
        autocomplete: this.isSearching ? 'list' : 'none',
        placeholder: undefined
      })), nativeInputProps), !this.native ? {
        type: 'text'
      } : {}),
      clearIconProps: Object.assign({
        ref: el => this.clearButtonRef(el, [clearIconRef]),
        onclick: e => this.onClearButtonClicked(e, originalClearIconClick)
      }, clearIconProps)
    }, inputProps, {
      value: this.inputText,
      focusedView: this.visible,
      onFocus: e => this.onInputFocused(e, originalOnInputFocus),
      onBlur: e => this.onInputBlurred(e, originalOnInputBlur),
      onClick: e => this.onInputClicked(e, originalOnInputClick),
      onValueChange: e => this.onInputValueChanged(e, originalOnInputValueChange),
      onKeyDown: e => this.onInputKeyDown(e, originalOnInputKeyDown),
      onValidityChange: stopEvent
    }), h("slot", {
      name: "assistive-text"
    }), h("owc-icon", Object.assign({
      name: "clock",
      slot: "prefix"
    }, hideElementFromAccessibilityAPI())));
  }
  getMenuElement(width) {
    const _a = this.menuProps || {},
      {
        style: menuStyle
      } = _a,
      _b = _a.listProps,
      _c = _b === void 0 ? {} : _b,
      {
        ref: listRef = undefined,
        onSelectedChange = undefined
      } = _c,
      listProps = __rest(_c, ["ref", "onSelectedChange"]),
      {
        onClickOutside
      } = _a,
      menuProps = __rest(_a, ["style", "listProps", "onClickOutside"]);
    return h("owc-menu", Object.assign({
      style: Object.assign({
        width: `${width}px`,
        minWidth: `${width}px`,
        maxWidth: `${width}px`
      }, menuStyle),
      usage: "dropdown",
      position: "bottom",
      trigger: "manual",
      autoCloseOnSelection: true
    }, menuProps, {
      listProps: Object.assign(Object.assign(Object.assign({
        selected: [dateFns.getTime(this.value)],
        onSelectedChange: ev => this.onListSelectedChanged(ev, onSelectedChange),
        ref: el => this.listRef(el, [listRef]),
        hostRole: 'listbox'
      }, accessibilityAttributes({
        required: this.required,
        readonly: !this.isSearching,
        label: this.label
      })), listProps), {
        notTabbable: true
      }),
      anchor: this.inputRef.current,
      visible: this.visible,
      onVisibleChange: e => {
        this.visible = e.detail;
      },
      onClickOutside: e => this.onMenuClickOutside(e, onClickOutside)
    }), this.generateListItems());
  }
  render() {
    var _a, _b, _c;
    const width = ((_b = (_a = this.inputRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.width) || ((_c = this.hostElement.getBoundingClientRect()) === null || _c === void 0 ? void 0 : _c.width) || 0;
    const hostClasses = this.component.getHostBemClasses({
      [`variant-${this.variant || 'filled'}`]: true,
      focused: this.focused
    });
    return h(Host, {
      key: '0e45548cc610af0423caf5cb78b548d025a2c473',
      class: hostClasses
    }, this.getInputElement(), this.getMenuElement(width));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "step": ["onValidatorParamsChange"],
      "minTime": ["onValidatorParamsChange"],
      "maxTime": ["onValidatorParamsChange"],
      "format": ["onValidatorParamsChange"],
      "locale": ["onValidatorParamsChange"],
      "required": ["onValidatorParamsChange"],
      "unavailable": ["onValidatorParamsChange"],
      "selectOutOfStep": ["onValidatorParamsChange"],
      "value": ["onValueChanged"],
      "validity": ["onValidityChange"],
      "disabled": ["onDisabledChanged"],
      "visible": ["onVisibleChanged"],
      "searchTerm": ["onSearchTermChanged"]
    };
  }
  static get style() {
    return OwcTimepickerStyle0;
  }
}, [6, "owc-timepicker", {
  "name": [513],
  "disabled": [516],
  "format": [513],
  "label": [513],
  "locale": [16],
  "maxTime": [514, "max-time"],
  "minTime": [514, "min-time"],
  "native": [516],
  "noClearIcon": [516, "no-clear-icon"],
  "disableValidation": [516, "disable-validation"],
  "placeholder": [513],
  "required": [516],
  "selectOutOfStep": [516, "select-out-of-step"],
  "size": [513],
  "controlled": [516],
  "step": [514],
  "unavailable": [16],
  "validity": [16],
  "validationTrigger": [513, "validation-trigger"],
  "controlledValidity": [516, "controlled-validity"],
  "value": [1538],
  "variant": [513],
  "visible": [1028],
  "inputProps": [16],
  "menuProps": [16],
  "selectAllCheckboxState": [32],
  "inputText": [32],
  "isSearching": [32],
  "searchTerm": [32],
  "allItemsHidden": [32],
  "minTimeVal": [32],
  "maxTimeVal": [32],
  "focused": [32],
  "timeList": [32],
  "validate": [64]
}, undefined, {
  "step": ["onValidatorParamsChange"],
  "minTime": ["onValidatorParamsChange"],
  "maxTime": ["onValidatorParamsChange"],
  "format": ["onValidatorParamsChange"],
  "locale": ["onValidatorParamsChange"],
  "required": ["onValidatorParamsChange"],
  "unavailable": ["onValidatorParamsChange"],
  "selectOutOfStep": ["onValidatorParamsChange"],
  "value": ["onValueChanged"],
  "validity": ["onValidityChange"],
  "disabled": ["onDisabledChanged"],
  "visible": ["onVisibleChanged"],
  "searchTerm": ["onSearchTermChanged"]
}]);
__decorate([OnClosestFormReset()], Timepicker.prototype, "onClosestFormReset", null);
__decorate([OnClosestFormSubmit()], Timepicker.prototype, "onClosestFormSubmit", null);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-timepicker", "owc-card", "owc-checkbox", "owc-divider", "owc-focus-trap", "owc-icon", "owc-icon-button", "owc-input", "owc-list", "owc-list-item", "owc-menu", "owc-popover", "owc-radio", "owc-tooltip"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-timepicker":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, Timepicker);
        }
        break;
      case "owc-card":
        if (!customElements.get(tagName)) {
          defineCustomElement$e();
        }
        break;
      case "owc-checkbox":
        if (!customElements.get(tagName)) {
          defineCustomElement$d();
        }
        break;
      case "owc-divider":
        if (!customElements.get(tagName)) {
          defineCustomElement$c();
        }
        break;
      case "owc-focus-trap":
        if (!customElements.get(tagName)) {
          defineCustomElement$b();
        }
        break;
      case "owc-icon":
        if (!customElements.get(tagName)) {
          defineCustomElement$a();
        }
        break;
      case "owc-icon-button":
        if (!customElements.get(tagName)) {
          defineCustomElement$9();
        }
        break;
      case "owc-input":
        if (!customElements.get(tagName)) {
          defineCustomElement$8();
        }
        break;
      case "owc-list":
        if (!customElements.get(tagName)) {
          defineCustomElement$7();
        }
        break;
      case "owc-list-item":
        if (!customElements.get(tagName)) {
          defineCustomElement$6();
        }
        break;
      case "owc-menu":
        if (!customElements.get(tagName)) {
          defineCustomElement$5();
        }
        break;
      case "owc-popover":
        if (!customElements.get(tagName)) {
          defineCustomElement$4();
        }
        break;
      case "owc-radio":
        if (!customElements.get(tagName)) {
          defineCustomElement$3();
        }
        break;
      case "owc-tooltip":
        if (!customElements.get(tagName)) {
          defineCustomElement$2();
        }
        break;
    }
  });
}
const OwcTimepicker = Timepicker;
const defineCustomElement = defineCustomElement$1;
export { OwcTimepicker, defineCustomElement };