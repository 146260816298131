/*eslint-disable @typescript-eslint/explicit-function-return-type */
import { APP_INITIALIZER, inject, Injectable } from '@angular/core';
import { initShellApp } from 'shell-browser/app.js';
import 'shell-browser/widget.js';
import { BuiltInResolvers } from 'shell-browser/region.js';

import { UrlResolverService } from '../../shell/url-resolver.service';
import { EnvConfigService } from '../services/env-config.service';

const urlService = new UrlResolverService();

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  envConfigService = inject(EnvConfigService);

  public async initApp(): Promise<void> {
    await this.envConfigService.Init();

    initShellApp({
      appId: this.envConfigService.config?.serviceName ?? '',
      env: this.envConfigService.config?.environmentName ?? '',
      appName: 'qnsbn eService',
      region: {
        resolvers: [
          // Ideally, this would be the BuiltInResolvers.url but it's currently not working as expected
          // This resolver enables manual country:language change directly from the URL
          urlService,
          // this resolver will try to load your previously selected country:language from the cache
          BuiltInResolvers.cache,
          // If unrecognized country:language config is passed, and there is no cache this resolver tries to redirect you to the default of your user (works only if user is logged in)
          BuiltInResolvers.user,
          // This resolver defaults to your browser region if unrecognized country:language config is passed.
          // Deleting this resolver will cause the app to show country selection if unrecognized country:language config is passed.
          BuiltInResolvers.browser,
        ],
      },
    });
  }
}

export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory:
    (appInitializerService: AppInitializerService): (() => Promise<void>) =>
    () =>
      appInitializerService.initApp(),
  deps: [AppInitializerService],
};
