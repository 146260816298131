<ng-container>
  @if(isLoggedIn){
    <div class="page-content">
      @if(spinBool){
        <div class="spinner-overlay">
          <owc-progress-spinner size="xxLarge" value="50" />
        </div>
      }
      <div class="cn-notification-container">
        <owc-card class="notification-list" style="box-shadow: none;">
          <div>
            <div class="notification-list-title">
              {{ 'Notifications overview' | transloco }}
            </div>
            <div class="notification-search-rows">
              @if(loading){
                <dl-notification-search 
                (searchValue)="searchValueInp($event)">
                </dl-notification-search>
              }
            </div>
          </div>
          <div style="margin-top: 4rem; margin-bottom: 1rem;">
            @if(loading){
              <dl-notification-filter 
              [filterOptions]="filterOptions" 
              (filterOptEmit)="filterOptInp($event)">
              </dl-notification-filter>
            }
          </div>
        </owc-card>
        @if(loading){
          <dl-notification-table 
          [tableData]="notificationsList" 
          [filterOptSel]="filterOptSel" 
          [accSelected]="accSelected" 
          [searchValueSel]="searchValueSel">
          </dl-notification-table>
        }
      </div>
    </div>
  }@else {
    <div class="page-content">
      <div class="cn-notification-container">
        <owc-card class="notification-list">
          <div class="notLogIn">
            Please login to see your notifications.
          </div>
        </owc-card>
      </div>
    </div>
  }
</ng-container>

