import { K as KeyboardCode, J as KeydownManager, E as KeydownAction } from './p-a00c815e.js';

/* eslint-disable max-lines-per-function */
const PAGE_JUMP_OFFSET = 10;
const generateDropdownKeydownManager = (ddRef, focusTargetRef, listRef) => {
  return {
    targetLazy: () => focusTargetRef,
    opts: {
      scroll: {
        container: () => listRef
      },
      keydownHandlers: [{
        key: [KeyboardCode.Tab, {
          code: KeyboardCode.Tab,
          shift: true
        }],
        handler: () => {
          listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => {
            manager.clearFocus();
            manager.setStartIndex(-1);
            manager.scrollToFirstElement();
          });
        },
        preventDefault: false
      }, {
        key: KeydownManager.DefaultKeys[KeydownAction.CLOSE],
        disabled: () => !ddRef.visible,
        handler: () => ddRef.visible = false
      }, {
        key: [KeyboardCode.ArrowDown],
        handler: props => {
          if (ddRef.visible) {
            listRef === null || listRef === void 0 ? void 0 : listRef.dispatchEvent(new KeyboardEvent(props.event.type, props.event));
          } else {
            ddRef.visible = true;
            listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => {
              manager.clearFocus();
              manager.setCurrentItem(manager.getActualSelectedItem() || manager.getFirstItem(), props, true);
            });
          }
        },
        preventDefault: false
      }, {
        key: [{
          code: KeyboardCode.ArrowDown,
          alt: true
        }],
        handler: () => {
          ddRef.visible = true;
          listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => {
            manager.clearFocus();
            manager.setStartIndex(-1);
            manager.scrollToFirstElement();
          });
        },
        disabled: () => ddRef.visible
      }, {
        key: [KeyboardCode.ArrowUp],
        handler: props => {
          if (ddRef.visible) {
            listRef === null || listRef === void 0 ? void 0 : listRef.dispatchEvent(new KeyboardEvent(props.event.type, props.event));
          } else {
            ddRef.visible = true;
            listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => manager.setCurrentItem(manager.getLastItem(), props));
          }
        }
      }, {
        key: [KeyboardCode.ArrowRight, KeyboardCode.ArrowLeft],
        handler: props => {
          if (ddRef.visible) {
            listRef === null || listRef === void 0 ? void 0 : listRef.dispatchEvent(new KeyboardEvent(props.event.type, props.event));
          }
        },
        preventDefault: true,
        disabled: () => !ddRef.visible
      }, {
        key: [KeyboardCode.Home],
        handler: props => {
          ddRef.visible = true;
          listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => manager.setCurrentItem(manager.getFirstItem(), props));
        }
      }, {
        key: [KeyboardCode.End],
        handler: props => {
          ddRef.visible = true;
          listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => manager.setCurrentItem(manager.getLastItem(), props));
        }
      }, {
        key: [KeyboardCode.PageUp],
        handler: props => {
          listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => manager.setCurrentItem(manager.getItem(manager.getCurrentIndex() - PAGE_JUMP_OFFSET, false), props));
        },
        disabled: () => !ddRef.visible
      }, {
        key: [KeyboardCode.PageDown],
        handler: props => {
          listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => manager.setCurrentItem(manager.getItem(manager.getCurrentIndex() + PAGE_JUMP_OFFSET, false), props));
        },
        disabled: () => !ddRef.visible
      }, {
        key: [KeyboardCode.Enter, KeyboardCode.NumpadEnter],
        handler: props => {
          if (ddRef.visible) {
            listRef === null || listRef === void 0 ? void 0 : listRef.dispatchEvent(new KeyboardEvent(props.event.type, props.event));
            ddRef.focus();
          } else {
            ddRef.visible = true;
            listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => {
              manager.clearFocus();
              manager.setCurrentItem(manager.getActualSelectedItem() || manager.getFirstItem(), props, true);
            });
          }
        }
      }, {
        key: [KeyboardCode.Space],
        handler: props => {
          if (ddRef.visible) {
            listRef === null || listRef === void 0 ? void 0 : listRef.dispatchEvent(new KeyboardEvent(props.event.type, props.event));
            ddRef.focus();
          } else {
            ddRef.visible = true;
            listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => {
              manager.clearFocus();
              manager.setCurrentItem(manager.getActualSelectedItem() || manager.getFirstItem(), props, true);
            });
          }
        },
        preventDefault: true
      }, {
        key: [{
          code: KeyboardCode.ArrowUp,
          alt: true
        }],
        handler: props => {
          listRef === null || listRef === void 0 ? void 0 : listRef.getKeydownManager().then(manager => {
            manager.DefaultKeydownHandler[KeydownAction.SELECT](props);
            ddRef.focus();
          });
        },
        disabled: () => !ddRef.visible
      }]
    }
  };
};
export { generateDropdownKeydownManager as g };