import { Component, inject, OnInit } from '@angular/core';
import { TranslocoRootModule } from '../../transloco-root.module';
import { watchIsLoggedIn } from 'shell-browser/auth.js';
import { NgShellRxjsWrapperService } from '../../shell/ng-shell-wrapper.service';
import { Observable, debounceTime } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotificationSearchComponent } from '../notification-search/notification-search.component';
import { OneWebComponentsAngularModule } from '@one/angular';
import { NotificationFilterComponent } from '../notification-filter/notification-filter.component';
import { NotificationTableComponent } from '../notification-table/notification-table.component';
import { CustomerNotificationService } from './customer-notification.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  standalone: true,
  imports: [TranslocoRootModule, NotificationSearchComponent, OneWebComponentsAngularModule, NotificationFilterComponent, NotificationTableComponent],
})

export class MainPageComponent implements OnInit {
  readonly ngShellService = inject(NgShellRxjsWrapperService);
  customerservice = inject(CustomerNotificationService);
  http = inject(HttpClient);
  isLoggedIn$: Observable<boolean>;
  isLoggedIn: boolean = false;
  loading: boolean = false;
  notificationsList: any;
  accSelected: string;
  filterOptions: { types: string[]; statuses: string[] } 
  filterOptSel: { types: string[]; statuses: string[] } 
  searchValueSel: any;
  spinBool:boolean = false;
  
  ngOnInit(): void {
    this.getIsLoggedIn();
    this.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.performLoggedInActions();
      } else {
        this.performLoggedOutActions();
      }
    });
  }

  private getIsLoggedIn(): void {
    this.isLoggedIn$ = this.ngShellService.toObservable(watchIsLoggedIn);
  }

  // Actions to perform when the user is logged 
  private async performLoggedInActions(): Promise<void> {
    this.spinBool = true;
    await this.getNotificationDetails();
  }

  // Actions to perform when the user is logged out
  private performLoggedOutActions(): void {
  }

  private getNotificationDetails(): void {
    this.customerservice.accountSelector$.subscribe(
      res => {
        this.accSelected = res;
        if(res === 'My notifications'){
          this.customerservice.getNotificationTableData(['all'],['all']).pipe(debounceTime(500)).subscribe(
            (data) => {
              this.notificationsList = data;
              this.filterOptions = this.extractFilters(data);
              this.loading = true;
              this.spinBool = false;
            }
          );
        }else{
          this.customerservice.getAccountDetails(res,['all'],['all']).pipe(debounceTime(500)).subscribe(
            (data) => {
              this.notificationsList = data;
              this.filterOptions = this.extractFilters(data);
              this.loading = true;
              this.spinBool = false;
            }
          );
        }
      },
      err => {
        this.spinBool = false;
        console.log(err,'error for getNotificationDetails');
      }
    );
  }

  extractFilters(data: any[]): { types: string[], statuses: string[] } {
    return  {
      types: this.extractTypes(data),
      statuses: this.extractStatuses(data)
    }
  }

  private  extractTypes(data: any[]): string[] {
    const types = data.map(item => item.Type);
    return Array.from(new Set(types));
  }

  private extractStatuses(data: any[]): string[] {
    const statuses = data.map(item => (item.CustomerStatus || item.Status));
    return [...Array.from(new Set(statuses)), 'Completed'];
  }

  filterOptInp(data){
    this.filterOptSel = data;
  }

  searchValueInp(data){
    this.searchValueSel = data;
  }
}
