/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, from, switchMap } from 'rxjs';
import { getAccessToken } from 'shell-browser/auth.js';

import { NgShellRxjsWrapperService } from '../../shell/ng-shell-wrapper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  readonly ngShellService = inject(NgShellRxjsWrapperService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('/customer-api/v1')) {
      return from(getAccessToken()).pipe(
        switchMap((token: string | null) => {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: token,
              },
            });
          }
          return next.handle(request);
        }),
      );
    }

    return next.handle(request);
  }
}
