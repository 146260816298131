import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, throwError } from 'rxjs';
import customerAPIURLs from '../services/customer-API-URLs';
import { AppUtils } from '../utils/app-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { snackbarService } from '@one/web-components';
import { watchAllRegions } from 'shell-browser/region.js';

@Injectable({
  providedIn: 'root'
})
export class CustomerNotificationService {
  public notificationTableData: any;
  public notificationData: any;
  public accessToken = '';
  public data: any;
  public notificationId: any;
  public customerId: any;
  public apiKey = customerAPIURLs.apiKey;
  public storeNotificationTableData: any;
  private notificationLocalId = new Subject<string>();
  public localId$ = this.notificationLocalId.asObservable();
  private accountSelector = new BehaviorSubject<string>('My notifications');
  public accountSelector$ = this.accountSelector.asObservable();

  public constructor(
    public http: HttpClient,
    public appUtil: AppUtils,
  ) {}

  public getLocalStorageItem(text: string): any {
    for (let i = 0; i < localStorage.length; i++) {
      const lsKeyName: string = localStorage.key(i);
      if (lsKeyName.includes(text)) {
        return localStorage.getItem(lsKeyName);
      }
    }
  }

  public getNotificationTableData(type, status): Observable<any> {
    const apiurl = window.origin 
    +customerAPIURLs.baseURLParam 
    +"overview"
    +"?typeFilter="+type
    +"&statusFilter="+status;
    return this.http.get(apiurl);
  }

  public getNotificationDetails(rowData: any): Observable<any> {
    this.notificationId = rowData.NotificationId;
    this.customerId = rowData.CustomerId;
    const apiurl = window.origin + customerAPIURLs.baseURLParam + customerAPIURLs.notificationDetailsData + this.notificationId + customerAPIURLs.customerIdData + this.customerId;
    return this.http.get(apiurl);
  }
  public getService(url: string): Observable<any> {
    return this.http.get(url);
  }

  public getServiceNoHeader(url: string): Observable<any> {
    return this.http.get(url);
  }

  public downloadPiosDocumentApi(PIMID: any, fileExtension: string): void {
    const apiurl = window.origin + customerAPIURLs.downloadDocument + PIMID;
    this.appUtil.fetchDoc(apiurl, this.apiKey, this.accessToken, fileExtension);
  }

  public downloadLocalAttachmentApi(filename: string, notificationId: string, contactNotificationId: string, publicKey: string): void {
    const userType = localStorage.getItem('userType');
    let apiurl = '';
    if (userType === 'isNonDialogUser') {
      apiurl = window.origin + customerAPIURLs.nonDialogDownloadUrl + 'filename=' + filename + '&notificationid=' + notificationId + '&contactnotificationid=' + contactNotificationId + '&publickey=' + publicKey;
      this.getServiceNoHeader(apiurl).subscribe(
        res => {
          if (res) {
            const downloadURL = res.body; // )['uploadURL'];
            this.appUtil.fetchDoc(downloadURL, '', '', filename);
          }
        },
        (error: HttpErrorResponse) => {
          // console.log('error:', error);
          this.httpErrorHandler(error, 'Could not fetch Document Data.');
        }
      );
    } else {
      apiurl = window.origin + customerAPIURLs.baseURLParam + customerAPIURLs.downloadLocalAttachment + 'Filename=' + filename + '&NotificationId=' + notificationId;
      this.getService(apiurl).subscribe(
        res => {
          if (res) {
            const downloadURL = res.body; // )['uploadURL'];
            this.appUtil.fetchDoc(downloadURL, '', '', filename);
          }
        },
        (error: HttpErrorResponse) => {
          // console.log('error:', error);
          this.httpErrorHandler(error, 'Could not fetch Document Data.');
        }
      );
    }
  }
  public httpErrorHandler(error: any, errMessage: string): void {
    if (error.status === 401) {
      const errorMessage = this.appUtil.getTranslatedMessage('401');
      snackbarService.show({
        message: errorMessage, 
        snackbarProps: {
          status: 'alarm',
          direction: 'horizontal',
          showClose: true,
          elevated: false,
          duration: 5000,
          verticalPlacement: 'top',
          horizontalPlacement: 'right',
        }
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      snackbarService.show({
        message: errMessage, 
        snackbarProps: {
          status: 'alarm',
          direction: 'horizontal',
          showClose: true,
          elevated: false,
          duration: 5000,
          verticalPlacement: 'top',
          horizontalPlacement: 'right',
        }
      });
    }
  }
  public putService(feedBackData: any): Observable<any> {
    const userType = localStorage.getItem('userType');
    let apiurl = '';
    if (userType === 'isNonDialogUser') {
      apiurl = window.origin + customerAPIURLs.nonDialogFeedbackSubmit + 'notificationid=' + feedBackData.NotificationId + '&contactnotificationid=' + feedBackData.ContactNotificationId + '&publickey=' + feedBackData.PublicKeyToken;
      return this.http.put(apiurl, feedBackData, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    } else {
      apiurl = window.origin + customerAPIURLs.feedBackParam;
      return this.http.put(apiurl, feedBackData);
    }
  }

  public getNotificationDetailsDirectLink(urlData: any): Observable<any> {
    // ?notificationId=9e25dcd1-9e56-4960-a447-a44039e9ad65&contactNotificationId=fc9dde01-57dd-4caf-bbda-da7e69284962&publicKeyToken=3021e833-1f28-40f6-b1a1-294a04ed8cc0&country=GB
    const notificationId = this.getUrlVar(urlData, 'notificationId');
    const contactNotificationId = this.getUrlVar(urlData, 'contactNotificationId');
    const publicKeyToken = this.getUrlVar(urlData, 'publicKeyToken');
    const customerId = this.getUrlVar(urlData, 'customerId');
    const detailAPIBaseUrl = window.origin + customerAPIURLs.baseURLParam + customerAPIURLs.notificationDetailsData + notificationId;
    const apiParam = contactNotificationId && contactNotificationId !== 'undefined' ? '&contactnotificationid=' + contactNotificationId : '&customerid=' + customerId;
    let apiurl = detailAPIBaseUrl + apiParam;
    if (publicKeyToken && publicKeyToken !== 'undefined') {
      apiurl = window.origin + customerAPIURLs.notificationDetailsNonDialogData + 'notificationid=' + notificationId + '&contactnotificationid=' + contactNotificationId + '&publickey=' + publicKeyToken;
      return this.http.get(apiurl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    } else {
      return this.http.get(apiurl);
    }
  }

  public getUrlVar(urlString: any, key: any): any {
    const result = new RegExp(key + '=([^&]*)', 'i').exec(urlString);
    return (result && unescape(result[1])) || '';
  }

  public setLocalId(localid: string): void {
    this.notificationLocalId.next(localid);
  }

  public getAccountDropdownList(): Observable<any> {
    const apiurl = window.origin 
    +customerAPIURLs.getAccountDropdownList
    return this.http.get(apiurl);
  }

  public getAccountDetails(accountId, type, status): Observable<any> {
    const apiurl = window.origin 
    +customerAPIURLs.getAccountDetails
    +accountId
    +"&type="+type
    +"&status="+status;
    return this.http.get(apiurl);
  }
  
  public accountSelectorData(accId: string): void {
    this.accountSelector.next(accId);
  }

  public throwError(...args:any): Observable<any> {
    return throwError(() => new Error('This is a simulated error!'));
  }
}
