<div class="welcome-container">
    <div class="welcome-content">
        <div class="background-shape"></div>
        <div class="background-thread"></div>
        <div class="background-kite"></div>
        <div class="welcome-text-block">
            <h3>{{'Sent it!'|transloco}}</h3>
            <p>{{ 'Your Navify account helps you save time by automatically filling in your contact details' | transloco }}</p>
        </div>
    </div>
</div>