<div class="page-content">
        
    <owc-card class="contact-card">
        
        <form [formGroup]="contactDataForm" class="contact-form">
            
            <owc-select-dropdown
            class="field"
            ngDefaultControl
            formControlName="fcCountry"
            [label]="'Location'|transloco"
            [placeholder]="'- Country'|transloco">
            <owc-list-item [title]="'- Country'|transloco" [value]="">
                {{'- Country'|transloco}}
            </owc-list-item>
            @for(item of countryList; track $index){
                <owc-list-item [value]="item" [title]="item">
                    {{item}}
                </owc-list-item>
            }
            </owc-select-dropdown>
            
            <owc-input
            class="field"
            [label]="'Email'|transloco"
            formControlName="fcEmail"
            >
            <owc-assistive-text *ngIf="contactDataForm.controls['fcEmail'].invalid" class="assText">
                {{'Email must be in in proper email format (i.e abc@abc.com)'|transloco}}
            </owc-assistive-text>
            </owc-input>
            
            <owc-input
            class="field"
            [label]="'First name'|transloco"
            formControlName="fcFirstName"
            >
            <owc-assistive-text *ngIf="contactDataForm.controls['fcFirstName'].invalid" class="assText">
                {{'First name must be at least 2 characters and should not contain any numeric or special character'|transloco}}
            </owc-assistive-text>
            </owc-input>
            
            <owc-input
            class="field"
            [label]="'Middle name (Optional)'|transloco"
            formControlName="fcMiddleName"
            >
            <owc-assistive-text *ngIf="contactDataForm.controls['fcMiddleName'].invalid" class="assText">
                {{'Middle name should be at least 1 characters and should not contain any numeric or special character'|transloco}}
            </owc-assistive-text>
            </owc-input>
            
            <owc-input
            class="field"
            [label]="'Last name'|transloco"
            formControlName="fcLastName"
            >
            <owc-assistive-text *ngIf="contactDataForm.controls['fcLastName'].invalid" class="assText">
                {{'Last name must be at least 2 characters and should not contain any numeric or special character'|transloco}}
            </owc-assistive-text>
            </owc-input>
           
            <div class="contact-form-actionbutton">
                <owc-button variant="secondary" (click)="Oncancel()" class='cnl-btn'>{{'Back'|transloco}}</owc-button>
                <owc-button [disabled]="contactDataForm.invalid" (click)="SubmitFeedback()">{{'Submit'|transloco}}</owc-button>
            </div>
            
        </form>
        
    </owc-card>
        
</div>
