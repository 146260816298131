import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { OneWebComponentsAngularModule } from '@one/angular';
import { Router } from '@angular/router';
import { AppUtils } from '../utils/app-utils';
import { CustomerNotificationService } from '../main-page/customer-notification.service';
import { NgClass } from '@angular/common';
import { snackbarService } from '@one/web-components';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'dl-notification-table',
  standalone: true,
  imports: [OneWebComponentsAngularModule, NgClass, TranslocoModule],
  templateUrl: './notification-table.component.html',
  styleUrl: './notification-table.component.scss',
  providers: [CustomerNotificationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationTableComponent  implements OnInit{
  appUtils = inject(AppUtils); 
  router = inject(Router); 
  changeDetect = inject(ChangeDetectorRef); 
  customerservice = inject(CustomerNotificationService); 
  @Input() tableData: any[] = [];
  @Input() accSelected:string;
  @Input() filterOptSel: { types: string[]; statuses: string[] } 
  @Input() searchValueSel:string;
  initPageLoad = false;
  filterBool = false;
  searchBool = false;
  previousAccSel: any;
  previousOptSel: any;
  previousSearchSel: any;
  previoustableData: any;
  ogTableData: any;
  spinBool = false;
  sortInfo: { column: string, direction: string }[] = [{ column: 'DateDelivered', direction: 'desc' }];

  ngOnChanges(changes: SimpleChanges){
    if (changes['accSelected'] && changes['accSelected'].currentValue !== this.previousAccSel) {
      this.previousAccSel = changes['accSelected'].currentValue;
      this.filterOptSel = { types: [], statuses: [] };
      this.spinBool = true;
    }
    if (changes['filterOptSel'] && changes['filterOptSel'].currentValue !== this.previousOptSel) {
      this.previousOptSel = changes['filterOptSel'].currentValue;
      this.filterOptSelected(this.filterOptSel);
    }
    if (changes['searchValueSel'] && changes['searchValueSel'].currentValue !== this.previousSearchSel) {
      this.previousSearchSel = changes['searchValueSel'].currentValue;
      this.searchInput(this.searchValueSel || '');
    }
    if (changes['tableData'] && changes['tableData'].currentValue !== this.previoustableData) {
      this.tableData = this.applySorting(this.tableData);
      this.previoustableData = changes['tableData'].currentValue;
      this.ogTableData = [...this.tableData];
      this.spinBool = false;
      if(this.tableData.length < 1) {
        this.initPageLoad = true;
      }else{
        this.initPageLoad = false;
      }
    }
  }
  
  ngOnInit(): void {
  }

  filterOptSelected(filterValue){
    if(filterValue?.typeFilters.length <= 0 ){
      filterValue.typeFilters = ['all'];
    }
    if(filterValue?.statusFilters.length <= 0 ){
      filterValue.statusFilters = ['all'];
    }
    if(this.accSelected === 'My notifications'){
      this.getTableFilterData(filterValue.typeFilters, filterValue.statusFilters);
    }
    if(this.accSelected !== 'My notifications'){
      this.getAccFilterData(this.accSelected, filterValue.typeFilters, filterValue.statusFilters)
    }
  }

  getTableFilterData(type, status){
    this.customerservice.getNotificationTableData(type, status).subscribe(
      (data) => {
        this.tableData = this.applySorting(data);
        this.ogTableData = [...data];
        this.searchInput(this.searchValueSel || '');
        if(this.tableData.length < 1) {
          this.filterBool = true;
        }else{
          this.filterBool = false;
          this.searchBool = false;
        }
        this.changeDetect.detectChanges();
      },
      err => {
        this.initPageLoad = true;
      }
    );
  }

  getAccFilterData(accSelected, type, status){
    this.customerservice.getAccountDetails(accSelected, type, status).subscribe(
      data => {
        this.tableData = this.applySorting(data);
        this.ogTableData = [...data];
        this.searchInput(this.searchValueSel || '');
        if(this.tableData.length < 1) {
          this.filterBool = true;
        }else{
          this.filterBool = false;
          this.searchBool = false;
        }
        this.changeDetect.detectChanges();
      },
      err => {
        const feedbackFailed = this.appUtils.getTranslatedMessage('AccountFailed');
        this.initPageLoad = true;
        snackbarService.show({
          message: feedbackFailed, 
          snackbarProps: {
            status: 'alarm',
            direction: 'horizontal',
            showClose: true,
            elevated: false,
            duration: 5000,
            verticalPlacement: 'top',
            horizontalPlacement: 'right',
          }
        });
      }
    );
  }

  searchInput(searchValueSel: any): void {
    const searchString = searchValueSel.toLowerCase().trim();
    const filteredData = this.ogTableData.filter(iter => {
      return this.appUtils.getLowerCase(iter.Type).trim().includes(searchString) 
      || this.appUtils.getLowerCase(iter.LocalId).trim().includes(searchString) 
      || this.appUtils.getLowerCase(iter.Title).trim().includes(searchString) 
      || this.appUtils.getLowerCase(iter.CustomerName || iter.AccountName).trim().includes(searchString)
      || this.appUtils.formatDateOnly(iter.DateDelivered).toLowerCase().trim().includes(searchString) 
      || this.appUtils.formatDateOnly(iter.FeedbackDueDate).toLowerCase().trim().includes(searchString)
      || this.appUtils.getLowerCase(iter.CustomerStatus || iter.Status).trim().includes(searchString)
    });
    this.tableData = this.applySorting(filteredData);
    if(this.tableData.length < 1) {
      this.searchBool = true;
    }else{
      this.searchBool = false;
      this.filterBool = false;
    }
    this.changeDetect.detectChanges();
  }

  OpenNotificationDetails(row: any): void {
    const urlparam = `notificationId=${row.NotificationId}&contactnotificationid=${row.ContactNotificationId}&customerId=${row.CustomerId || row.AccountId}`;
    sessionStorage.setItem('urlParameters', urlparam);
    this.router.navigate(['customer-notification-details']);
  }

  public OnSort(column: string, value: string): void {
    const existingSortIndex = this.sortInfo.findIndex(sort => sort.column === column);
    if (existingSortIndex !== -1) {
      this.sortInfo.splice(existingSortIndex, 1);
    }
    if (value !== 'default') {
      this.sortInfo.push({ column, direction: value });
    }
    this.tableData = this.applySorting(this.ogTableData);
    this.changeDetect.detectChanges();
  }

  applySorting(data: any[]): any[] {
    const dataCopy = [...data]; 
    if (this.sortInfo.length > 0) {
      return dataCopy.sort((a, b) => {
        for (let i = this.sortInfo.length - 1; i >= 0; i--) {
          const { column, direction } = this.sortInfo[i];
          const aValue = a[column];
          const bValue = b[column];
          if (aValue < bValue) {
            return direction === 'asc' ? -1 : 1;
          } else if (aValue > bValue) {
            return direction === 'asc' ? 1 : -1;
          }
        }
        return 0;
      });
    } else {
      return dataCopy; 
    }
  }
}
