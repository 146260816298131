export default {
    baseURLParam: '/customer-api/v1/notifications/local/',
    notificationDetailsData: 'details?notificationid=',
    customerIdData: '&customerid=',
    downloadDocument: '/customer-api/v1/pios/document?documentid=',
    downloadLocalAttachment: 'attachment/downloadurl?',
    apiKey: 'JO7shhIkpz35Ct28pcd0F7QhNjZOUZWh8xwgbwfs',
    feedBackParam: '/customer-api/v1/notifications/contact/feedback',
    notificationDetailsNonDialogData: '/customer-api/v1/notifications/nonregistered/local/details?',
    nonDialogDownloadUrl: '/customer-api/v1/notifications/nonregistered/local/attachment/downloadurl?',
    nonDialogFeedbackSubmit: '/customer-api/v1/notifications/nonregistered/contact/feedback?',
    getAccountDropdownList: '/customer-api/v1/notifications/account',
    getAccountDetails: '/customer-api/v1/notifications/accountById?accountId='
};
  