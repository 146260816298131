<shell-root>

  <shell-header slot="header">

    <!-- desktop view logo -->
    <a slot="logo">
      <shell-app-logo>
        <img
          slot="icon"
          alt="Icon placeholder"
          style="width: 30px"
          src="assets/eService_logo.svg"
        />
        <span one-topbar-title [routerLink]="getRouterLink('main')" style="cursor: pointer; font-size: medium;">
          Product & Safety Care
        </span>
      </shell-app-logo>
    </a>

    <!-- mobile view logo -->
    <a slot="mobile-logo">
      <shell-app-logo hide-content>
        <img
          slot="icon"
          alt="Icon placeholder"
          src="assets/eService_logo.svg"
        />
      </shell-app-logo>
    </a>

    <!-- Optional actions presented as icons in the header -->
    <!-- Mobile view actions to be defined in shell-sidenav -->
    <shell-actions slot="actions">
      @if(!localIdBool && isLoggenIn){
        <owc-select-dropdown
        [value]="selectedValue"
        [variant]="'filled'"
        [noClearIcon]="true" 
        [limit]="4"
        >
        @for (item of selectOptList; track item) {
          <owc-list-item [value]="item.value" [title]="item.title" (click)="getAccountDetails(item.value)">
              {{item.label}}
          </owc-list-item>
        }
        </owc-select-dropdown>
      }
    </shell-actions>

    <!-- Common navbar (desktop & mobile) -->
    <shell-nav-menu slot="nav-menu">
      <shell-nav-menu-item
        *ngIf="!localIdBool"
        [routerLink]="getRouterLink('main')"
        routerLinkActive
        [attr.active]="true"
      >
        {{ 'Notifications overview' | transloco }}
      </shell-nav-menu-item>
      <shell-nav-menu-item *ngIf="localIdBool">
        <strong class="truncate-text" [title]="localId">{{localId}}</strong>
      </shell-nav-menu-item>
    </shell-nav-menu>
    
  </shell-header>

  <!-- Router with app body -->
  <router-outlet></router-outlet>

  <shell-footer slot="footer"></shell-footer>

  <!-- side panel shown on the left in mobile -->
  <shell-sidenav slot="sidenav">

    <a slot="logo">
      <shell-app-logo>
        <span [routerLink]="getRouterLink('main')" style="cursor: pointer; font-size: medium;">
          P&SC
        </span>
      </shell-app-logo>
    </a>

    <!-- optional action to be displayed in the side nav other action include login/logout and lanugage change -->
    <shell-actions slot="actions">
      @if(!localIdBool && isLoggenIn){
        <owc-select-dropdown
        [value]="selectedValue"
        [variant]="'filled'"
        [noClearIcon]="true" 
        [limit]="4"
        >
        @for (item of selectOptList; track item) {
          <owc-list-item [value]="item.value" [title]="item.title" (click)="getAccountDetails(item.value)">
              {{item.label}}
          </owc-list-item>
        }
        </owc-select-dropdown>
      }
    </shell-actions>

  </shell-sidenav>
  
</shell-root>