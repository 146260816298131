import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteResolverService implements Resolve<boolean> {

  constructor(private router: Router){}

  resolve(route: ActivatedRouteSnapshot): boolean {
    const qp = route.queryParams;
    if (qp['publicKeyToken']) {
      this.router.navigate(['customer-notification-details']);
      return false;
    } else {
      return true;
    }
  }
}