import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-25bf8c52.js';
import { b as generateElementId, m as isElementReady } from './p-604842db.js';
import { b as accessibility } from './p-756e7dc5.js';
import { v as doWhenDefaultIsNotPrevented, J as KeydownManager, L as KeydownTemplate } from './p-a00c815e.js';
import { u as useRef } from './p-e952b680.js';
import { a as ComponentUtils } from './p-cf58d171.js';
import { d as defineCustomElement$4 } from './p-7dca3d48.js';
import { d as defineCustomElement$3 } from './p-43e98172.js';
import { d as defineCustomElement$2 } from './p-fc8ae07f.js';
import { d as defineCustomElement$1 } from './p-2977eb6f.js';
const modalDialogCss = ".sc-owc-modal-dialog-h{--backdrop-padding:0;display:none}.owc-modal-dialog--visible.sc-owc-modal-dialog-h{display:-ms-flexbox;display:flex}.owc-modal-dialog--visible.sc-owc-modal-dialog-h>.owc-modal-dialog__backdrop-element.sc-owc-modal-dialog owc-focus-trap.sc-owc-modal-dialog{width:100%}@media (forced-colors: active){.owc-modal-dialog--visible.sc-owc-modal-dialog-h>.owc-modal-dialog__backdrop-element.sc-owc-modal-dialog owc-focus-trap.sc-owc-modal-dialog owc-card.sc-owc-modal-dialog{border:var(--one-spacing-outline-bold) solid canvastext;border-radius:var(--one-spacing-radius-5-xs)}}.owc-modal-dialog--elevated.sc-owc-modal-dialog-h .owc-modal-dialog__backdrop-element__card-element.sc-owc-modal-dialog{-webkit-box-shadow:var(--one-elevation-box-shadow-24);box-shadow:var(--one-elevation-box-shadow-24)}.owc-modal-dialog--size-small.sc-owc-modal-dialog-h owc-focus-trap.sc-owc-modal-dialog{max-width:calc(var(--one-spacing-size-7-xs) * 540)}.owc-modal-dialog--size-medium.sc-owc-modal-dialog-h owc-focus-trap.sc-owc-modal-dialog{max-width:calc(var(--one-spacing-size-7-xs) * 720)}.owc-modal-dialog--size-large.sc-owc-modal-dialog-h owc-focus-trap.sc-owc-modal-dialog{max-width:calc(var(--one-spacing-size-7-xs) * 960)}";
const OwcModalDialogStyle0 = modalDialogCss;
var __rest = undefined && undefined.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
const TIME_INTERVAL = 50;
const NUMBER_OF_ATTEMPTS = 40;
const ModalDialog = /*@__PURE__*/proxyCustomElement(class ModalDialog extends H {
  constructor() {
    super();
    this.__registerHost();
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.slotWrapperRef = useRef();
    this.onBackdropClick = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      doWhenDefaultIsNotPrevented(ev, () => !this.disableBackdropClick && (this.visible = false));
    };
    this.component = new ComponentUtils(this).withKeydownManager([KeydownManager.Templates[KeydownTemplate.OVERLAY]]);
    this.visible = false;
    this.disableBackdropClick = false;
    this.disableEscapeKeydown = false;
    this.disableScrollLock = false;
    this.hideBackdrop = false;
    this.size = 'medium';
    this.cardProps = undefined;
    this.backdropProps = undefined;
    this.noAutoFocus = false;
    this.cardID = generateElementId('card');
    this.isContentLoaded = false;
  }
  handleVisibleChanges(newValue, oldValue) {
    !!oldValue !== !!newValue && this.visibleChange.emit(newValue);
  }
  componentDidLoad() {
    isElementReady(() => this.slotWrapperRef.current, NUMBER_OF_ATTEMPTS, TIME_INTERVAL).then(() => {
      this.isContentLoaded = true;
    });
  }
  render() {
    const _a = this.cardProps || {},
      {
        elevated = true,
        className: cardOriginalClassName
      } = _a,
      cardProps = __rest(_a, ["elevated", "className"]);
    const hostClasses = this.component.getHostBemClasses({
      [`size-${this.size || 'medium'}`]: true,
      visible: this.visible,
      elevated
    });
    const _b = this.backdropProps || {},
      {
        onBackdropClick: originalBackdropClick,
        className: backdropOriginalClassName
      } = _b,
      backdropProps = __rest(_b, ["onBackdropClick", "className"]);
    const backdropClasses = this.component.getChildBemClasses('backdrop-element', hostClasses, {}, backdropOriginalClassName);
    const cardClasses = this.component.getChildBemClasses('card-element', backdropClasses, {}, cardOriginalClassName);
    return h(Host, Object.assign({
      key: '25269433d02da133bcde8d4e238ea4781930e8bb',
      class: hostClasses,
      tabIndex: !this.visible ? -1 : this.component.getInitialTabIndex()
    }, accessibility({
      role: 'dialog',
      aria: {
        modal: true
      }
    })), h("owc-backdrop", Object.assign({
      key: 'bebdfbf7d4f0a71062cd2d7c665b7112cf8a2a5e',
      class: backdropClasses,
      hidden: !this.visible,
      opacity: this.hideBackdrop ? 0 : undefined,
      disableScrollLock: this.disableScrollLock
    }, backdropProps, {
      onBackdropClick: e => this.onBackdropClick(e, originalBackdropClick)
    }), h("owc-focus-trap", {
      key: '9e016d7857638742a9f04dd8b8a2f783daaba1d7',
      active: this.isContentLoaded && this.visible,
      autoFocusType: this.noAutoFocus ? 'none' : 'auto',
      exitWithEscape: !this.disableEscapeKeydown,
      onExit: () => this.visible = false,
      trappedElement: this.slotWrapperRef.current
    }, h("owc-card", Object.assign({
      key: 'e80eb4e0c76442e565646db6488830c93db77aaf',
      class: cardClasses
    }, cardProps, {
      elevated: elevated,
      id: this.cardID,
      contentWrapperProps: {
        ref: this.slotWrapperRef
      }
    }), h("slot", {
      key: '28f8a86606b8808ba6adc49486ba204b25dffa91',
      name: "header"
    }), h("slot", {
      key: 'a7b0e3417a2c14d22f2c553d82ff62d77a881189'
    }), h("slot", {
      key: '1fa38e0738632e9069afa7394a282217b5d6c955',
      name: "footer"
    })))));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "visible": ["handleVisibleChanges"]
    };
  }
  static get style() {
    return OwcModalDialogStyle0;
  }
}, [6, "owc-modal-dialog", {
  "visible": [1540],
  "disableBackdropClick": [516, "disable-backdrop-click"],
  "disableEscapeKeydown": [516, "disable-escape-keydown"],
  "disableScrollLock": [516, "disable-scroll-lock"],
  "hideBackdrop": [516, "hide-backdrop"],
  "size": [513],
  "cardProps": [16],
  "backdropProps": [16],
  "noAutoFocus": [516, "no-auto-focus"],
  "cardID": [32],
  "isContentLoaded": [32]
}, undefined, {
  "visible": ["handleVisibleChanges"]
}]);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-modal-dialog", "owc-backdrop", "owc-card", "owc-focus-trap", "owc-icon"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-modal-dialog":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, ModalDialog);
        }
        break;
      case "owc-backdrop":
        if (!customElements.get(tagName)) {
          defineCustomElement$4();
        }
        break;
      case "owc-card":
        if (!customElements.get(tagName)) {
          defineCustomElement$3();
        }
        break;
      case "owc-focus-trap":
        if (!customElements.get(tagName)) {
          defineCustomElement$2();
        }
        break;
      case "owc-icon":
        if (!customElements.get(tagName)) {
          defineCustomElement$1();
        }
        break;
    }
  });
}
export { ModalDialog as M, defineCustomElement as d };