import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Config } from '../models/config.model';

@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  public config: Config | null = null;
  public header = {};

  public constructor() {}

  public async Init(): Promise<void> {
    this.config = {
      serviceName: 'qnsbn-eservice-customer',
      environmentName: environment.name
    }
  }
}
