import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output } from '@angular/core';
import { OneWebComponentsAngularModule } from '@one/angular';

@Component({
  selector: 'dl-notification-search',
  templateUrl: './notification-search.component.html',
  styleUrl: './notification-search.component.scss',
  standalone: true,
  imports: [OneWebComponentsAngularModule],
})

export class NotificationSearchComponent {
  @Output() searchValue = new EventEmitter();

  public applyFilter(searchValue: string): void {
    this.searchValue.emit(searchValue);
  }
}
