import { p as proxyCustomElement, H } from './p-25bf8c52.js';
const LifecycleActivator = /*@__PURE__*/proxyCustomElement(class LifecycleActivator extends H {
  constructor() {
    super();
    this.__registerHost();
    this.loaded = undefined;
  }
  connectedCallback() {
    /* leave blank */
  }
  disconnectedCallback() {
    /* leave blank */
  }
  componentWillRender() {
    /* leave blank */
  }
  componentDidRender() {
    /* leave blank */
  }
  componentWillLoad() {
    /* leave blank */
  }
  componentDidLoad() {
    /* leave blank */
  }
  componentShouldUpdate() {
    /* leave blank */
  }
  componentWillUpdate() {
    /* leave blank */
  }
  componentDidUpdate() {
    /* leave blank */
  }
}, [0, "internal-lifecycle-activator", {
  "loaded": [4]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["internal-lifecycle-activator"];
  components.forEach(tagName => {
    switch (tagName) {
      case "internal-lifecycle-activator":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, LifecycleActivator);
        }
        break;
    }
  });
}
const InternalLifecycleActivator = LifecycleActivator;
const defineCustomElement = defineCustomElement$1;
export { InternalLifecycleActivator, defineCustomElement };