import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainPageComponent } from './features/main-page/main-page.component';
import { CustomerNotificationDetailsComponent } from './features/customer-notification-details/customer-notification-details.component';
import { CustomerContactDetailsComponent } from './features/customer-contact-details/customer-contact-details.component';
import { NondialogCustomerWelcomeComponent } from './features/nondialog-customer-welcome/nondialog-customer-welcome.component';
import { RouteResolverService } from './features/services/route-resolver.service';

export const routes: Routes = [
  {
    path: ':country/:language',
    children: [
      {
        path: 'main',
        component: MainPageComponent,
      },
      // when the shell redirects to [country]/[region], redirect by default to "main"
      {
        path: '**',
        resolve: { data: RouteResolverService },
        component: MainPageComponent,
      },
    ],
  },
  {
    path: 'main',
    component: MainPageComponent,
  },
  {
    path: 'customer-notification-details',
    component: CustomerNotificationDetailsComponent,
  },
  {
    path: 'contact-details',
    component: CustomerContactDetailsComponent,
  },
  {
    path: 'welcome',
    component: NondialogCustomerWelcomeComponent,
  },
  // when a path is not found, redirect to "main"
  {
    path: '**',
    component: MainPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
