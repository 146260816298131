import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-25bf8c52.js';
import { M as MultiChildrenWrapper, d as defineCustomElement$8 } from './p-fa64486b.js';
import { i as isSameArray } from './p-d7a5de56.js';
import { v as isDefined } from './p-604842db.js';
import './p-a00c815e.js';
import { u as useRef } from './p-e952b680.js';
import { a as ComponentUtils } from './p-cf58d171.js';
import { d as defineCustomElement$j } from './p-c63d33da.js';
import { d as defineCustomElement$i } from './p-db4cdc02.js';
import { d as defineCustomElement$h } from './p-43e98172.js';
import { d as defineCustomElement$g } from './p-c922fcf3.js';
import { d as defineCustomElement$f } from './p-54f516a8.js';
import { d as defineCustomElement$e } from './p-337473a5.js';
import { d as defineCustomElement$d } from './p-bed53612.js';
import { d as defineCustomElement$c } from './p-fc8ae07f.js';
import { d as defineCustomElement$b } from './p-2977eb6f.js';
import { d as defineCustomElement$a } from './p-1be53978.js';
import { d as defineCustomElement$9 } from './p-8495f6f6.js';
import { d as defineCustomElement$7 } from './p-744a991c.js';
import { d as defineCustomElement$6 } from './p-fe94825f.js';
import { d as defineCustomElement$5 } from './p-ddd9f091.js';
import { d as defineCustomElement$4 } from './p-e9171c24.js';
import { d as defineCustomElement$3 } from './p-7708f872.js';
import { d as defineCustomElement$2 } from './p-40870078.js';
const editableDropdownCss = ".sc-owc-editable-dropdown-h{display:-ms-inline-flexbox;display:inline-flex;width:calc(var(--one-spacing-size-7-xs) * 250)}.owc-editable-dropdown--loading.sc-owc-editable-dropdown-h owc-list-item.sc-owc-editable-dropdown{visibility:hidden}";
const OwcEditableDropdownStyle0 = editableDropdownCss;
const MENU_ITEM_LIMIT = 6;
const EditableDropdown = /*@__PURE__*/proxyCustomElement(class EditableDropdown extends H {
  constructor() {
    super();
    this.__registerHost();
    this.valueChange = createEvent(this, "valueChange", 3);
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.clearClick = createEvent(this, "clearClick", 3);
    this.validityChange = createEvent(this, "validityChange", 3);
    this.itemAdd = createEvent(this, "itemAdd", 3);
    this.baseDropdownRef = useRef();
    this.component = new ComponentUtils(this, {
      patchFocusMethod: () => this.focusDropdownElement(),
      patchBlurMethod: () => this.blurDropdownElement()
    });
    this.focusDropdownElement = () => {
      var _a;
      if (!this.disabled && !this.focused) {
        (_a = this.baseDropdownRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        this.focused = true;
      }
    };
    this.blurDropdownElement = () => {
      var _a;
      if (this.focused) {
        (_a = this.baseDropdownRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        this.focused = false;
      }
    };
    this.visible = false;
    this.validity = {
      state: 'valid'
    };
    this.disabled = false;
    this.label = undefined;
    this.limit = MENU_ITEM_LIMIT;
    this.name = undefined;
    this.placeholder = undefined;
    this.required = false;
    this.value = undefined;
    this.variant = 'filled';
    this.size = 'medium';
    this.rounded = false;
    this.notFoundText = 'Not Found';
    this.noClearIcon = false;
    this.disableValidation = false;
    this.addItemOnMatch = false;
    this.notSelectableValues = [];
    this.selectableValues = [];
    this.controlled = false;
    this.validationTrigger = 'input, blur';
    this.controlledValidity = false;
    this.inputProps = undefined;
    this.menuProps = undefined;
    this.tooltipProps = undefined;
    this.clearButtonProps = undefined;
    this.menuTriggerButtonProps = undefined;
    this.loading = true;
    this.focused = false;
    this.internalValue = [];
  }
  onValueChanged() {
    const newInternalValue = [this.value].filter(isDefined);
    !isSameArray(this.internalValue, newInternalValue) && (this.internalValue = newInternalValue);
  }
  /** Triggers the validation */
  validate() {
    var _a, _b;
    return Promise.resolve((_b = (_a = this === null || this === void 0 ? void 0 : this.baseDropdownRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.validate());
  }
  componentWillLoad() {
    this.onValueChanged();
  }
  componentDidLoad() {
    this.loading = false;
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      disabled: this.disabled,
      loading: this.loading
    });
    return h(Host, {
      key: 'e98b492d40973a8dcdf3091f83fcf55c42e832e0',
      class: hostClasses,
      tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex()
    }, h("owc-base-dropdown", {
      key: '7a2bb8def4562b02ee74d332b7a423b713143126',
      ref: this.baseDropdownRef,
      visible: this.visible,
      validity: this.validity,
      disableValidation: this.disableValidation,
      disabled: this.disabled,
      label: this.label,
      limit: this.limit,
      name: this.name,
      placeholder: this.placeholder,
      required: this.required,
      value: this.internalValue,
      controlled: this.controlled,
      variant: this.variant,
      size: this.size,
      rounded: this.rounded,
      searchable: true,
      editable: true,
      notFoundText: this.notFoundText,
      noClearIcon: this.noClearIcon,
      addItemOnMatch: this.addItemOnMatch,
      selectableValues: this.selectableValues,
      notSelectableValues: this.notSelectableValues,
      validationTrigger: this.validationTrigger,
      controlledValidity: this.controlledValidity,
      inputProps: this.inputProps,
      menuProps: this.menuProps,
      tooltipProps: this.tooltipProps,
      clearButtonProps: this.clearButtonProps,
      menuTriggerButtonProps: this.menuTriggerButtonProps,
      onValueChange: e => {
        var _a, _b;
        if (!isSameArray(this.internalValue, e.detail)) {
          !this.controlled && (this.value = (_a = e.detail) === null || _a === void 0 ? void 0 : _a[0]);
          this.valueChange.emit((_b = e.detail) === null || _b === void 0 ? void 0 : _b[0]);
        }
      },
      onVisibleChange: e => this.visibleChange.emit(e.detail),
      onClickOutside: () => this.clickOutside.emit(),
      onValidityChange: e => this.validityChange.emit(e.detail),
      onClearClick: () => this.clearClick.emit(),
      onItemAdd: e => this.itemAdd.emit(e.detail),
      onFocus: () => {
        this.focused = true;
        this.hostElement.dispatchEvent(new KeyboardEvent('focus'));
      },
      onBlur: () => {
        this.focused = false;
        this.hostElement.dispatchEvent(new KeyboardEvent('blur'));
      }
    }, h("slot", {
      key: 'e16155826ef9a1535da230df719c8808bff1b81d',
      name: "prefix"
    }), h("slot", {
      key: '4c91f7de1d1beeb237b9bf833752b7ecb54283cb',
      name: "suffix"
    }), h("slot", {
      key: '4c4a5fe01598827260d6dc481933e4ba09ebd6b1',
      name: "assistive-text"
    }), h(MultiChildrenWrapper, {
      key: 'b9e9620c369f10b0a61851cd474b75d5551fd7ab'
    }, h("slot", {
      key: '5d2e2aaf410260c5635e513a3a44cb6223a15564'
    }))));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "value": ["onValueChanged"]
    };
  }
  static get style() {
    return OwcEditableDropdownStyle0;
  }
}, [6, "owc-editable-dropdown", {
  "visible": [1540],
  "validity": [16],
  "disabled": [516],
  "label": [513],
  "limit": [514],
  "name": [513],
  "placeholder": [513],
  "required": [516],
  "value": [520],
  "variant": [513],
  "size": [513],
  "rounded": [516],
  "notFoundText": [513, "not-found-text"],
  "noClearIcon": [516, "no-clear-icon"],
  "disableValidation": [516, "disable-validation"],
  "addItemOnMatch": [4, "add-item-on-match"],
  "notSelectableValues": [16],
  "selectableValues": [16],
  "controlled": [516],
  "validationTrigger": [513, "validation-trigger"],
  "controlledValidity": [516, "controlled-validity"],
  "inputProps": [16],
  "menuProps": [16],
  "tooltipProps": [16],
  "clearButtonProps": [16],
  "menuTriggerButtonProps": [16],
  "loading": [32],
  "focused": [32],
  "internalValue": [32],
  "validate": [64]
}, undefined, {
  "value": ["onValueChanged"]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-editable-dropdown", "owc-base-dropdown", "owc-button", "owc-card", "owc-checkbox", "owc-component-footer", "owc-component-header", "owc-divider", "owc-focus-trap", "owc-icon", "owc-icon-button", "owc-input", "owc-list", "owc-list-item", "owc-menu", "owc-popover", "owc-radio", "owc-tooltip", "owc-typography"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-editable-dropdown":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, EditableDropdown);
        }
        break;
      case "owc-base-dropdown":
        if (!customElements.get(tagName)) {
          defineCustomElement$j();
        }
        break;
      case "owc-button":
        if (!customElements.get(tagName)) {
          defineCustomElement$i();
        }
        break;
      case "owc-card":
        if (!customElements.get(tagName)) {
          defineCustomElement$h();
        }
        break;
      case "owc-checkbox":
        if (!customElements.get(tagName)) {
          defineCustomElement$g();
        }
        break;
      case "owc-component-footer":
        if (!customElements.get(tagName)) {
          defineCustomElement$f();
        }
        break;
      case "owc-component-header":
        if (!customElements.get(tagName)) {
          defineCustomElement$e();
        }
        break;
      case "owc-divider":
        if (!customElements.get(tagName)) {
          defineCustomElement$d();
        }
        break;
      case "owc-focus-trap":
        if (!customElements.get(tagName)) {
          defineCustomElement$c();
        }
        break;
      case "owc-icon":
        if (!customElements.get(tagName)) {
          defineCustomElement$b();
        }
        break;
      case "owc-icon-button":
        if (!customElements.get(tagName)) {
          defineCustomElement$a();
        }
        break;
      case "owc-input":
        if (!customElements.get(tagName)) {
          defineCustomElement$9();
        }
        break;
      case "owc-list":
        if (!customElements.get(tagName)) {
          defineCustomElement$8();
        }
        break;
      case "owc-list-item":
        if (!customElements.get(tagName)) {
          defineCustomElement$7();
        }
        break;
      case "owc-menu":
        if (!customElements.get(tagName)) {
          defineCustomElement$6();
        }
        break;
      case "owc-popover":
        if (!customElements.get(tagName)) {
          defineCustomElement$5();
        }
        break;
      case "owc-radio":
        if (!customElements.get(tagName)) {
          defineCustomElement$4();
        }
        break;
      case "owc-tooltip":
        if (!customElements.get(tagName)) {
          defineCustomElement$3();
        }
        break;
      case "owc-typography":
        if (!customElements.get(tagName)) {
          defineCustomElement$2();
        }
        break;
    }
  });
}
const OwcEditableDropdown = EditableDropdown;
const defineCustomElement = defineCustomElement$1;
export { OwcEditableDropdown, defineCustomElement };