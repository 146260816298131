import { ChangeDetectorRef, Component, OnInit, Renderer2, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RegionImpl, RegionDetailModel, watchActiveRegion, watchEnabledRegions } from 'shell-browser/region.js';
import { NgShellRxjsWrapperService } from './shell/ng-shell-wrapper.service';
import { CustomerNotificationService } from './features/main-page/customer-notification.service';
import { watchIsLoggedIn } from 'shell-browser/auth.js';
import { filter, map, Observable, Subscription } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})

export class AppComponent implements OnInit {
  readonly ngShellService = inject(NgShellRxjsWrapperService);
  readonly translocoService = inject(TranslocoService);
  router = inject(Router);
  changeDetect = inject(ChangeDetectorRef);
  customerservice = inject(CustomerNotificationService);
  routerSubscription: Subscription;
  regionalizedRoute: string = '';
  selectedValue: any;
  urlQueryParameter = sessionStorage.getItem('urlParameters');
  userType = localStorage.getItem('userType');
  isLoggenIn: boolean = false;
  selectOptList:any = [{
    label: 'My notifications',
    value: 'My notifications',
    title: 'My notifications'
  }];
  localId:string = '';
  localIdBool:boolean = false;

  ngOnInit(): void {
    this.getShellRouters();
    this.setShellLanguages();

    this.ngShellService.toObservable(watchIsLoggedIn).subscribe(
      isLoggedIn => {
        this.isLoggenIn = isLoggedIn
      }
    );

    if (this.isLoggenIn) {
      this.makeAccountCall();
      this.getLocalId();
      this.navigationToggle();
      this.customerservice.accountSelector$.subscribe(
        res => {
          this.selectedValue = res
        }
      );
    }

    if (this.isLoggenIn && this.userType !== 'isNonDialogUser') {
      localStorage.setItem('userType', 'isLoginDialogUser');
      this.userType = localStorage.getItem('userType');
    }
    
    if (!this.isLoggenIn && this.userType !== 'isNonDialogUser') {
      localStorage.setItem('userType', 'isNotLoginDialogUser');
      this.userType = localStorage.getItem('userType');
    }
  }

  private getShellRouters(): void {
    this.ngShellService
      .toObservable(watchActiveRegion)
      .pipe(untilDestroyed(this))
      .subscribe((region: RegionImpl) => {
        if (region.country && region.ietf) {
          this.regionalizedRoute = `${region.country}/${region.ietf.toLowerCase()}`;
          this.refreshRouteAfterLanguageChange();
          this.translocoService.setActiveLang(region.ietf);
        }
      });
  }

  getRouterLink(route: string): string {
    return `${this.regionalizedRoute}/${route}`;
  }

  private refreshRouteAfterLanguageChange(): void {
    const currentRouteWithoutRegionalization =
      this.router?.url.split('/').pop() ?? 'main';
    const newRoute = this.getRouterLink(currentRouteWithoutRegionalization);

    this.router.navigateByUrl(newRoute);
  }

  private setShellLanguages(): void {
    this.ngShellService
      .toObservable(watchEnabledRegions)
      .pipe(untilDestroyed(this))
      .subscribe((regions: RegionDetailModel[]) => {
        const languageList = regions
          .flatMap(region => region.languages)
          .map(
            l =>
              `${l.language.substring(0, 2)}${l.language.substring(2, 5).toUpperCase()}`,
          )
          .filter((value, index, self) => self.indexOf(value) === index);

        //Add default language from transloco to the list of possible languages even it its not available as the shell langauge
        //Safety fallback for when there is no translation file for created region
        const defaultTranslocoLang = this.translocoService.getDefaultLang();
        languageList.push(defaultTranslocoLang);

        this.translocoService.setAvailableLangs(languageList);
      });
  }
  
  getAccountDetails(accountId){
    this.customerservice.accountSelectorData(accountId);
  }

  async makeAccountCall(): Promise<void> {
    this.customerservice.getAccountDropdownList().subscribe(
      res => {
        this.selectOptList.push(
          ...res.body.response.content.accounts.map( acc => ({label: acc.account_name, value: acc.crmNumber, title: `${acc.crmNumber} (${acc.erpNumber})`}) )
        );
      }
    );
  }
  
  getLocalId(){
    this.customerservice.localId$.subscribe(localid => {
      this.localId = localid;
      if(this.localId){
        this.localIdBool = true;
      }else{
        this.localIdBool = false;
      }
      this.changeDetect.detectChanges();
    });
  }

  navigationToggle(){
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
      (event: NavigationEnd) => {
        if (window.location.href.includes('main')) {
          this.localIdBool = false;
          this.changeDetect.detectChanges();
        }
        if (window.location.href.includes('customer-notification-details')) {
          this.localIdBool = true;
          this.changeDetect.detectChanges();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
