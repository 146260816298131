import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomerNotificationService } from '../main-page/customer-notification.service';
import { TranslocoModule } from '@jsverse/transloco';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'dl-nondialog-customer-welcome',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './nondialog-customer-welcome.component.html',
  styleUrl: './nondialog-customer-welcome.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NondialogCustomerWelcomeComponent implements OnInit, AfterViewInit {
  public notificationData:any;
  public customerservice = inject(CustomerNotificationService);
  public constructor() {
    this.notificationData = history.state.notificationData;
  }

  public ngOnInit(): void {}
  
  public ngAfterViewInit(): void {
    const localId = this.notificationData ? this.notificationData.LocalId : '';
    this.customerservice.setLocalId(localId);
  }

  public openRegistration(): void {
    const rememberedLocation = JSON.parse(localStorage.getItem('rememberMyLocation'));
    const url = `https://${environment.dialogUrl}/${rememberedLocation.countryCode}/${rememberedLocation.languageCode}/registration`;
    this.openLink(url, false);
  }

  openLink(href: string, openInNewTab?: boolean): void {
    const a = document.createElement('a');
    a.href = href;
    a.setAttribute('referrerpolicy', 'no-referrer-when-downgrade');
    if (openInNewTab) {
      a.setAttribute('target', '_blank');
    }
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  }
}
