import { ChangeDetectionStrategy, Inject, Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerNotificationService } from '../main-page/customer-notification.service';
import { AppUtils } from '../utils/app-utils';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common';
import { Subscription } from 'rxjs';
import { OneWebComponentsAngularModule } from '@one/angular';
import { snackbarService } from '@one/web-components';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'dl-customer-notification-details',
  standalone: true,
  imports: [OneWebComponentsAngularModule, NgIf, NgFor, NgClass, ReactiveFormsModule, TranslocoModule],
  templateUrl: './customer-notification-details.component.html',
  styleUrl: './customer-notification-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerNotificationDetailsComponent implements OnInit, OnDestroy {
  public isDisabled = true;
  public notificationDataObj: any;
  public feedBackDetails: any;
  public notificationDetailsObj: any;
  public customerDataObj: any;
  public feedBackData: any;
  public customerFeedbackItems: any;
  public feedbackobj: any;
  public feedbackDataForm: FormGroup;
  public isFeedBackSubmitted = false;
  public isCompleted = false;
  public userFileSize = { size: 0, type: '' };
  public urlQueryParameter = sessionStorage.getItem('urlParameters');
  public userType = localStorage.getItem('userType');
  public isNonDialogUser = this.userType === 'isNonDialogUser' ? true : false;
  public notificationType = '';
  public isNonDialogSBNType = false;
  public isApIFailed = false;
  public msgAPIFailed = '';
  public remindersData = [];
  public isReminderDisabled = false;
  public ContactNotificationObj: any;
  private _accSelectorSubscription: Subscription | undefined;
  public accSelector:string = '';
  public detailsAPIRes:any;
  public spinBool:boolean = false;
  public mainPageLink:string = '';

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    public cdr: ChangeDetectorRef,
    public fb: FormBuilder,
    public appUtil: AppUtils,
    public router: Router,
    public customerservice: CustomerNotificationService,
    public activateroute: ActivatedRoute,
    public appComponent: AppComponent
  ){}

  public ngOnDestroy(): void {
    if (!this.isNonDialogSBNType) {
      sessionStorage.removeItem('urlParameters');
    }
    this._accSelectorSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.mainPageLink = this.appComponent.getRouterLink('main');

    this._accSelectorSubscription = this.customerservice.accountSelector$.subscribe(
      res => {
        this.accSelector = res
      }
    );
    
    this.toggleLogin();
  }
  
  toggleLogin(){
    if (this.userType === 'isLoginDialogUser' || this.userType === 'isNonDialogUser') {
      this.customerservice.getNotificationDetailsDirectLink(this.urlQueryParameter).subscribe(
        (data: any) => {
          if (Object.keys(data).length > 0) {
            this.detailsAPIRes = data;
            this.notificationDetailsInit(data, this.accSelector);
          } else {
            const errorMessage = this.appUtil.getTranslatedMessage('203');
            this.customerservice.httpErrorHandler({ status: 203 }, errorMessage);
            this.isApIFailed = true;
            this.msgAPIFailed = errorMessage;
            this.cdr.detectChanges();
          }
        },
        (error: HttpErrorResponse) => {
          this.isApIFailed = true;
          if (error.status === 403) {
            this.msgAPIFailed = this.appUtil.getTranslatedMessage('403');
          } else if (error.status === 404) {
            this.msgAPIFailed = this.appUtil.getTranslatedMessage('404');
            setTimeout(() => {
              this.checkNonDialogUser();
              this.router.navigateByUrl(this.mainPageLink);
            }, 10000);
          } else {
            const errMessage = this.appUtil.getTranslatedMessage('detailFailed');
            this.customerservice.httpErrorHandler(error, errMessage);
            sessionStorage.removeItem('urlParameters');
            this.router.navigateByUrl(this.mainPageLink);
          }
          this.cdr.detectChanges();
        }
      );
    }else {
      const errorsMessage = this.appUtil.getTranslatedMessage('404');
      snackbarService.show({
        message: errorsMessage, 
        snackbarProps: {
          status: 'alarm',
          direction: 'horizontal',
          showClose: true,
          elevated: false,
          duration: 5000,
          verticalPlacement: 'top',
          horizontalPlacement: 'right',
        }
      });
      sessionStorage.removeItem('urlParameters');
      this.router.navigateByUrl(this.mainPageLink);
    }
  }

  public notificationDetailsInit(data: any, accSelector): void {

    if(accSelector === 'My notifications' && accSelector !== undefined){
      this.notificationDetailsObj = Array.isArray(data.ContactNotification) ? data.ContactNotification[0] : data.ContactNotification;
    }else{
      this.notificationDetailsObj = Array.isArray(data.AccountNotification) ? data.AccountNotification[0] : data.AccountNotification;
    }

    this.customerDataObj = Array.isArray(data.Notification) ? data.Notification[0] : data.Notification;
    this.ContactNotificationObj = Array.isArray(data.ContactNotification) ? data.ContactNotification[0] : data.ContactNotification;
    this.notificationType = this.customerDataObj.Type;
    this.isNonDialogSBNType = this.isNonDialogUser && this.notificationType === 'SBN' ? true : false;
    const localId = this.notificationDetailsObj ? this.notificationDetailsObj.LocalId : '';
    this.customerservice.setLocalId(localId);

    if (this.notificationDetailsObj.CustomerReminderDetails) {
      const remindersDataFiltered = this.notificationDetailsObj.CustomerReminderDetails.map((item, i) => {
        const reminderItem = this.customerDataObj.NotificationReminders.filter(reItem => reItem.ReminderId === item.ReminderId);
        return Object.assign({}, reminderItem[0], item);
      });
      this.remindersData = remindersDataFiltered.filter(reItem => reItem.ReminderSendStatus === "Delivered").reverse();
    }

    this.isReminderDisabled = this.remindersData.length === 1;
    this.feedbackDataForm = this.fb.group({});
    
    if (this.notificationDetailsObj && (this.notificationDetailsObj.CustomerStatus || this.notificationDetailsObj.Status) && this.customerDataObj.Status) {
      
      this.isFeedBackSubmitted = this.notificationDetailsObj.CustomerStatus === 'Feedback Submitted' 
      || this.notificationDetailsObj.CustomerStatus === 'Feedback Received'
      || this.notificationDetailsObj.Status === 'Feedback Submitted'
      || this.notificationDetailsObj.Status === 'Feedback Received';

      this.isCompleted = this.customerDataObj.Status === 'Completed';
      
    }

    if (this.customerDataObj && this.customerDataObj.CustomerFeedbackItems) {
      this.feedbackobj = this.customerDataObj.CustomerFeedbackItems;
      this.feedbackobj.forEach(formItem => {
        if(formItem.FeedbackItemMandatory && formItem.FeedbackItemMandatory === 'Optional') {
          if (formItem.FeedbackItemType === 'Checkbox') {
            this.feedbackDataForm.addControl(
              this.getFormControl(formItem.FeebackItemId),
              this.fb.control({ value: false, disabled: (this.isFeedBackSubmitted || this.isCompleted) })
            );
          } else {
            this.feedbackDataForm.addControl(
              this.getFormControl(formItem.FeebackItemId),
              this.fb.control({ value: '', disabled: (this.isFeedBackSubmitted || this.isCompleted) })
            );
          }
        } else {
          if (formItem.FeedbackItemType === 'Checkbox') {
            this.feedbackDataForm.addControl(
              this.getFormControl(formItem.FeebackItemId),
              this.fb.control({ value: false, disabled: (this.isFeedBackSubmitted || this.isCompleted) }, [Validators.requiredTrue])
            );
          } else {
            this.feedbackDataForm.addControl(
              this.getFormControl(formItem.FeebackItemId),
              this.fb.control({ value: '', disabled: (this.isFeedBackSubmitted || this.isCompleted) }, [Validators.required])
            );
          }
        }
      });
    }

    this.cdr.detectChanges();
  }

  public enablesubmit(): void {
    this.isDisabled = false;
  }

  public getSbtBtn(btnStatus: string): string {
    switch (btnStatus) {
      case 'New':
        return 'sbt-btn';
      case 'Read':
        return 'sbt-btn';
      case 'Reminder':
        return 'sbt-btn';
      case 'Feedback Overdue':
        return 'sbt-btn';
      case 'Feedback Submitted':
        return 'bck-btn';
      case 'Feedback Received':
        return 'bck-btn';
      case 'Completed':
        return 'bck-btn';
      default:
        return 'bck-btn';
    }
  }

  public getSubmitFormData(): void {
    const feedbackVar: any = [];
    const feedbackresArr = this.feedbackDataForm.value;
    const feedbackresponseVal = Object.values(feedbackresArr);

    this.feedbackobj.forEach(formItem => {
      feedbackVar.push({
        FeedbackResponseItemId: formItem.FeebackItemId,
        FeedbackResponseItemLabel: feedbackresponseVal[formItem.FeebackItemId]
      });
    });

    this.feedBackData = {
      NotificationId: this.notificationDetailsObj.NotificationId,
      NotificationType: this.notificationDetailsObj.Type,
      ReadAndUnderstood: true, // this.readconfirm.value,
      WillTakeAction: true, // this.actionconfirm.value,
      CustomerFeedbackResponseItems: feedbackVar,
      CustomerId: this.ContactNotificationObj.CustomerId || '',
      ContactNotificationId: this.ContactNotificationObj.ContactNotificationId || '',
      CustomerContactEmail: this.ContactNotificationObj.CustomerContactEmail || ''
    };

    if (this.isNonDialogUser) {
      this.feedBackData.PublicKeyToken = this.customerservice.getUrlVar(sessionStorage.getItem('urlParameters'), 'publicKeyToken');
    }
  }

  public SubmitFeedback(): void {
    this.spinBool = true;
    this.getSubmitFormData();
    this.customerservice.putService(this.feedBackData).subscribe(
      (response: HttpResponse<any>) => {
        this.spinBool = false;
        this.customerservice.storeNotificationTableData = null;
        const feedbackSubmitted = this.appUtil.getTranslatedMessage('FeedbackSubmitted');
        snackbarService.show({
          message: feedbackSubmitted, 
          snackbarProps: {
            status: 'positive',
            direction: 'horizontal',
            showClose: true,
            elevated: false,
            duration: 3000,
            verticalPlacement: 'top',
            horizontalPlacement: 'right',
          }
        });
        if (this.isNonDialogUser) {
          this.router.navigate(['welcome']);
        } else {
          this.router.navigateByUrl(this.mainPageLink);
        }
      },
      (error: HttpErrorResponse) => {
        this.spinBool = false;
        const feedbackFailed = this.appUtil.getTranslatedMessage('FeedbackFailed');
        snackbarService.show({
          message: feedbackFailed, 
          snackbarProps: {
            status: 'alarm',
            direction: 'horizontal',
            showClose: true,
            elevated: false,
            duration: 5000,
            verticalPlacement: 'top',
            horizontalPlacement: 'right',
          }
        });
      }
    );
  }

  public nonDialogConfirmFeedback(): void {
    this.getSubmitFormData();
    this.router.navigateByUrl('/contact-details', { state: { feedbackData: this.feedBackData, notificationData: this.notificationDetailsObj } });
  }

  public checkNonDialogUser(): void {
    const urlQueryString = sessionStorage.getItem('urlParameters');
    const publicKeyTokenvalue = this.customerservice.getUrlVar(urlQueryString, 'publicKeyToken');
    if (publicKeyTokenvalue && publicKeyTokenvalue !== 'undefined') {
      localStorage.setItem('userType', 'isNotLoginDialogUser');
    }
    sessionStorage.removeItem('urlParameters');
  }

  public Oncancel(): void {
    this.checkNonDialogUser();
    this.router.navigateByUrl(this.mainPageLink);
  }

  public OnBack(): void {
    this.checkNonDialogUser();
    this.router.navigateByUrl(this.mainPageLink);
  }

  public downloadDocument(PIMID: string, fileExtension: string): void {
    this.customerservice.downloadPiosDocumentApi(PIMID, fileExtension);
  }

  public downloadAttachment(fileName: string, notificationId: string): void {
    const urlQueryString = sessionStorage.getItem('urlParameters');
    const contactNotificationId = this.customerservice.getUrlVar(urlQueryString, 'contactNotificationId') || '';
    const publicKey = this.customerservice.getUrlVar(urlQueryString, 'publicKeyToken') || '';
    this.customerservice.downloadLocalAttachmentApi(fileName, notificationId, contactNotificationId, publicKey);
  }

  public getFormControl(ID: any): string {
    return 'formContolLabel' + ID;
  }

  public bytesCalculation(bytes: number, decimals: number = 2): any {
    if (bytes === 0) {
      this.userFileSize.size = 0;
      this.userFileSize.type = '';
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    this.userFileSize.size = 0;
    this.userFileSize.type = '';

    this.userFileSize.size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    this.userFileSize.type = sizes[i];
    return this.userFileSize;
  }

  public formatBytes(bytes: number, decimals: number = 2): string {
    this.bytesCalculation(bytes, decimals);
    return this.userFileSize.size + ' ' + this.userFileSize.type;
  }

}
