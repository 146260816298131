<div class="filter-container">

    <div class="filter-title">
      {{ 'Filter By:' | transloco }}
    </div>

    @if(this.accSelected === 'My notifications'){
        <owc-select-dropdown
        [value]="typeSelVal"
        [limit]="4"
        [placeholder]="'Type'|transloco"
        [showApplyButton]="true"
        [multiple]="(filterOptions.types).length > 0"
        [disabled]="(filterOptions.types).length < 1 && !filterOptions.types[0]"
        (valueChange)="typeListValue($event)"
        rounded
        >
        @for (item of filterOptions.types; track item) {
            <owc-list-item [value]="item | transloco">
                {{item | transloco}}
            </owc-list-item>
        }
        </owc-select-dropdown>
        
        <owc-select-dropdown
        [value]="statusSelVal"
        [limit]="4"
        [placeholder]="'Status'|transloco"
        [showApplyButton]="true"
        [multiple]="(filterOptions.statuses).length > 0"
        [disabled]="(filterOptions.statuses).length < 1 && !filterOptions.statuses[0]"
        (valueChange)="statusListValue($event)"
        rounded
        >
        @for (item of filterOptions.statuses; track item) {
            <owc-list-item [value]="item | transloco">
                {{item | transloco}}
            </owc-list-item>
        }
        </owc-select-dropdown>
    }

    <!-- Account -->
    @if(this.accSelected !== 'My notifications'){
        <owc-select-dropdown
        [value]="accTypeSelVal"
        [size]="'small'"
        [limit]="4"
        [placeholder]="'Type'|transloco"
        [showApplyButton]="true"
        [multiple]="(filterOptions.types).length > 0"
        [disabled]="(filterOptions.types).length < 1 && !filterOptions.types[0]"
        (valueChange)="typeListValue($event)"
        rounded
        >
        @for (item of filterOptions.types; track item) {
            <owc-list-item [value]="item | transloco">
                {{item | transloco}}
            </owc-list-item>
        }
        </owc-select-dropdown>

        <owc-select-dropdown
        [value]="accStatusSelVal"
        [size]="'small'"
        [limit]="4"
        [placeholder]="'Status'|transloco"
        [showApplyButton]="true"
        [multiple]="(filterOptions.statuses).length > 0"
        [disabled]="(filterOptions.statuses).length < 1 && !filterOptions.statuses[0]"
        (valueChange)="statusListValue($event)"
        rounded
        >
        @for (item of filterOptions.statuses; track item) {
            <owc-list-item [value]="item | transloco">
                {{item | transloco}}
            </owc-list-item>
        }
        </owc-select-dropdown>
    }
    
    @if(isClearbtnVisi){
        <owc-button variant="secondary" (click)="clear()"  rounded>Clear</owc-button>
    }
</div>