import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { OneWebComponentsAngularModule } from '@one/angular';
import { TranslocoModule } from '@jsverse/transloco';
import { CustomerNotificationService } from '../main-page/customer-notification.service';

@Component({
  selector: 'dl-notification-filter',
  standalone: true,
  imports: [OneWebComponentsAngularModule, TranslocoModule],
  templateUrl: './notification-filter.component.html',
  styleUrl: './notification-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationFilterComponent {
  customerservice = inject(CustomerNotificationService);
  typeSelVal: any;
  statusSelVal: any;
  accTypeSelVal: any;
  accStatusSelVal: any
  @Input() filterOptions = { types: [], statuses: [] };  
  @Output() filterOptEmit = new EventEmitter();
  filterOptSelG = { typeFilters: [], statusFilters: [] };
  filterOptAccSelG = { typeFilters: [], statusFilters: [] };
  isClearbtnVisi: boolean = false;  
  accSelected:string;

  ngOnInit(): void {
    this.customerservice.accountSelector$.subscribe(
      res => {
        this.accSelected = res
        this.isClearbtnVisi = false;
      }
    );
  }

  clear(){
    if(this.accSelected === 'My notifications'){
      this.typeSelVal = [];
      this.statusSelVal = [];
    }
    if(this.accSelected !== 'My notifications'){
      this.accTypeSelVal = [];
      this.accStatusSelVal = [];
    }
    this.updateTypes([]);
    this.updateStatus([]);
    this.isClearbtnVisi = false;
  }
  
  typeListValue(event: any){
    const types = event?.detail;
    this.updateTypes(types);
    if (types.length > 0) {
      this.isClearbtnVisi = true;
    } else {
      this.isClearbtnVisi = false;
    }
  }

  statusListValue(event: any){
    const status = event?.detail;
    this.updateStatus(status);
    if (status.length > 0) {
      this.isClearbtnVisi = true;
    } else {
      this.isClearbtnVisi = false;
    }
  }
  
  updateTypes(types){
    if(this.accSelected === 'My notifications'){
      if (types.length > 0) {
        this.filterOptSelG.typeFilters = types;
      };
      if (types.length <= 0) {
        this.filterOptSelG.typeFilters = [];
      };
      this.filterOptEmit.emit({...this.filterOptSelG});
    }
    if(this.accSelected !== 'My notifications'){
      if (types.length > 0) {
        this.filterOptAccSelG.typeFilters = types;
      };
      if (types.length <= 0) {
        this.filterOptAccSelG.typeFilters = [];
      };
      this.filterOptEmit.emit({...this.filterOptAccSelG});
    }
  }

  updateStatus(status){
    if(this.accSelected === 'My notifications'){
      if (status.length > 0) {
        this.filterOptSelG.statusFilters = status;
      };
      if (status.length <= 0) {
        this.filterOptSelG.statusFilters = [];
      };
      this.filterOptEmit.emit({...this.filterOptSelG});
    }
    if(this.accSelected !== 'My notifications'){
      if (status.length > 0) {
        this.filterOptAccSelG.statusFilters = status;
      };
      if (status.length <= 0) {
        this.filterOptAccSelG.statusFilters = [];
      };
      this.filterOptEmit.emit({...this.filterOptAccSelG});
    }
  }

}
